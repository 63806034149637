var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"body",class:_vm.clientWidth < 1200 && _vm.clientWidth > 1100
      ? 'medium'
      : '' || (_vm.clientWidth < 1100 && _vm.clientWidth > 900)
      ? 'small'
      : '' || (_vm.clientWidth < 900 && _vm.clientWidth > 720)
      ? 'tiny'
      : '' || (_vm.clientWidth < 720 && _vm.clientWidth >670) 
      ? 'ultra_tiny'
      : '' || _vm.clientWidth < 670
      ? 'mobile'
      : ''},[_c('a',{attrs:{"name":"about"}}),_c('h2',[_vm._v(_vm._s(_vm.title))]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont"},[_c('div',{staticClass:"col_l"},[_c('h3',[_vm._v("Компания «Акваберег»")]),_c('p',[_vm._v(" Выполняет все виды гидротехнических и ландшафтных работ на водоемах, в том числе очистку от мусора, ила и растений."),_c('br'),_c('br'),_vm._v(" Мы работаем на земснаряде «Боцман РА». Это плавающая малогабаритная техника с высокой производительностью: 160"),_c('sup',[_vm._v("м3")]),_vm._v(" по пульпе. Если в водоеме есть рыба, техника ей не навредит."),_c('br'),_c('br'),_vm._v(" Земснаряд передвигается по воде, поэтому не наносит ущерба ландшафту. По своей функциональности техника может заменить экскаватор, драглайн, кран или погрузчик. ")]),_c('h4',[_vm._v("Максимальная глубина работ - 4 м.")])]),_c('div',{staticClass:"col_r"},[_c('div',{staticClass:"preview_img"})])])}]

export { render, staticRenderFns }