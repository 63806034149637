
<template>
  <div class="overlay">
      <div class="content">
        <slot>

        </slot>
      </div>
  </div>
</template>

<script>
export default {
        data() {
            return {
            }
        },
        name: 'modal',
        props:{
            
        },
        components:{
        },
        computed: {

        },
        watch: {
            
        },
        methods: {

        }
    
}
</script>
<style scoped>
    .overlay{
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.85);
        backdrop-filter: blur(5px);
        z-index: 99;
    }
    .content{
        min-width: 400px;
    }

</style>