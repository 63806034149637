<template>
  <span>
    <!-- Dropdown Trigger -->

    <a
      class="dropdown-trigger btn col s1"
      :class="status.class"
      :data-target="i"
      ref="dropdown"
    >
      {{ status.text }}</a
    >

    <!-- Dropdown Structure -->
    <ul :id="i" class="dropdown-content">
      <li @click="statusIsNew"><a>новая</a></li>
      <li class="divider" tabindex="-1"></li>
      <li @click="statusInWork"><a>в работе</a></li>
      <li class="divider" tabindex="-1"></li>
      <li @click="statusIsDeleted"><a>удалено</a></li>
      <li class="divider" tabindex="-1"></li>
    </ul>
  </span>
</template>

<script>
import M from "materialize-css";

export default {
  name: "leadstatus",
  data() {
    return {
      inputData: "",
    };
  },
  mounted() {
    this.statusMenu = M.Dropdown.init(this.$refs.dropdown, {
      hover: false,
      // focusedIndex: 0,
      // coverTrigger: true
    })
  },
  watch: {
    inputData(newValue) {
      this.$emit("inputData", newValue);
    },
    openStatusMenu(newValue){
      if(newValue){
        this.statusMenu.open()
      }
    }
  },
  props: {
    openStatusMenu: Boolean,
    status: Object,
    id: String,
    lead: Object,
    getAll: Function,
    i: Number,
    statusClass: String,
  },
  computed: {},
  methods: {
    async statusIsNew() {
      M.toast({ html: `Статус заявки №${this.i + 1} изменён на: новая` });
      await this.$axios.post("lead/status", {
        id: this.lead._id,
        status: 0,
      });
      await this.getAll();
    },
    async statusInWork() {
      M.toast({ html: `Статус заявки №${this.i + 1} изменён на: в работе` });
      await this.$axios.post("lead/status", {
        id: this.lead._id,
        status: 1,
      });
      await this.getAll();
    },
    async statusIsDeleted() {
      await this.$axios.post("lead/status", {
        id: this.lead._id,
        status: 2,
      })
      await this.getAll()
      M.toast({ html: `Заявка №${this.i + 1} удалена` });
    },
  },
};
</script>

<style scoped>
.btn {
  width: 120px !important;
  height: 25px !important;
  line-height: 25px !important;
  color: #2c3e50;
  /* padding: 0 25px!important; */
  font-size: 11px !important;
}
.dropdown-content li > a,
.dropdown-content li > span {
  font-size: 12px !important;
  color: #26a69a;
  display: block;
  line-height: 22px;
  padding: 8px 8px;
}
.dropdown-content li {
  min-height: 20px !important;
  line-height: 1.5rem;
}
.dropdown-content li.divider {
  min-height: 0 !important;
  height: 1px !important;
}
</style>