<template>
  <div>
    <div class="date_picker" ref="date_picker">
      <div ref="startDatePicker"></div>
      <div ref="endDatePicker"></div>
    </div>

    <ul class="collection" id="header_wrp">
      <li class="collection-item row" id="header_block">
        <leadcheck @inputData="check" />
        <span>№</span>
        <span>статус</span>
        <span>клиент</span>
        <span>
          <i class="material-icons center">mail_outline</i>
        </span>
        <span>телефон</span>

        <span :class="!sort && !settings ? 'is_active' : 'is_hidden'"
          >когда перезвонить</span
        >
        <div class="white_space">
          <!-- filter  -->
          <div
            class="filter_wrp"
            :class="!sort ? 'normal' : 'is_moved'"
            ref="filter_wrp"
          >
            <i
              v-if="!sort && !settings"
              class="material-icons"
              @click="openFilter"
              ref="tooltippedFilter"
            >
              {{ icon }}
            </i>
            <span v-if="sort" @click="dropDown">
              <i class="material-icons">sort</i>
              фильтр:
              <i v-if="dropdown" class="material-icons">arrow_drop_up</i>
              <i v-else class="material-icons">arrow_drop_down</i></span
            >
            <i
              v-if="sort && !settings"
              class="material-icons close"
              @click="openFilter"
              ref="tooltippedFilter"
            >
              {{ icon }}
            </i>
          </div>

          <!-- settings -->
          <div
            class="settings_wrp"
            :class="!settings ? 'normal' : 'is_moved'"
            ref="settings_wrp"
          >
            <i
              v-if="!settings && !sort"
              class="material-icons"
              @click="openSettings"
              ref="tooltippedSettings"
            >
              settings
              <!-- {{ icon }} -->
            </i>
            <span v-if="settings" @click="dropDown">
              <i class="material-icons">settings</i>
              настройки
              <i v-if="dropdown" class="material-icons">arrow_drop_up</i>
              <i v-else class="material-icons">arrow_drop_down</i></span
            >
            <i
              v-if="settings"
              class="material-icons close"
              @click="openSettings"
              ref="tooltippedFilter"
            >
              close
              <!-- {{ icon }} -->
            </i>
          </div>
        </div>
      </li>
    </ul>

    <!-- filter dropdown -->
    <ul
      :class="dropdown ? 'is_active' : 'is_hidden'"
      class="filter_menu"
      v-if="sort"
    >
      <i
        v-if="filterStatus || filterDate"
        class="material-icons icon_dropdow_return"
        @click="closeSubDropDown"
        >keyboard_return</i
      >
      <li v-if="!filterStatus && !filterDate" class="filter_title active">
        по умолчанию
      </li>
      <li
        v-if="!filterDate"
        class="filter_title"
        @click="filterStatus = !filterStatus"
      >
        по статусу
      </li>
      <div v-if="filterStatus">
        <li
          v-for="(status, i) in status"
          :key="i"
          @click="filter(i)"
        >
          {{ status }}
        </li>
      </div>
      <li
        v-if="!filterStatus"
        @click="filterDate = !filterDate"
        class="filter_title"
      >
        по дате
      </li>
      <div v-if="filterDate">
        <li>выберите период:</li>
        <li class="date_input">
        <span @click="startDatePicker.open()" ref="startDatePicker">
          c: {{ startDate }}
        </span>
        <span @click="endDatePicker.open()" ref="endDatePicker">
          по: {{ endDate }}
        </span>
        </li>
      </div>
    </ul>

    <!-- settings dropdown -->
    <ul
      class="filter_settings"
      v-if="settings"
      :class="dropdown ? 'is_active' : 'is_hidden'"
    >
      <li class="settings_link">
        <leadcheck id="link_input" @inputData="showDeletedLeads" />
        отображать удалённые
      </li>
      <li class="settings_link">
        <leadcheck id="link_input" @inputData="showDeletedLeads" />
        отображать изменения
      </li>
      <li class="settings_link">
        <leadcheck id="link_input" @inputData="showDeletedLeads" />
        отображать сообщения
      </li>
      <li class="settings_link">
        <leadcheck id="link_input" @inputData="showDeletedLeads" />
        отображать дату создания
      </li>
    </ul>
  </div>
</template>

<script>
import { addDays, format } from "date-fns";
import M from "materialize-css";
import leadcheck from "./leadcheck.vue";
export default {
  name: "leadheader",
  data() {
    return {
      filterDate: false,
      filterStatus: false,
      date: { startDate: null, endDate: null },
      icon: "sort",
      dropdown: false,
      sort: false,
      settings: false,
      allChecked: false,
      onClick: false,
      helperText: "сортировать",
    };
  },
  mounted() {
    this.toolTipFilter = M.Tooltip.init(this.$refs.tooltippedFilter, {
      position: "right",
      html: this.helperText,
      exitDelay: 100,
    });
    this.toolTipSettings = M.Tooltip.init(this.$refs.tooltippedSettings, {
      position: "right",
      html: "настройки",
      exitDelay: 100,
    });
  },
  components: {
    leadcheck,
  },
  watch: {
    allChecked(newValue) {
      this.$emit("allChecked", newValue);
    },
    sort(newValue) {
      if (newValue) {
        this.dropdown = false;
        this.icon = "close";
        M.Tooltip.init(this.$refs.tooltippedFilter, {
          position: "right",
          html: "закрыть",
          exitDelay: 100,
        });
        this.mountDatePicker();
      } else {
        this.startDatePicker.destroy();
        this.endDatePicker.destroy();
        this.icon = "sort";
        M.Tooltip.init(this.$refs.tooltippedFilter, {
          position: "right",
          html: "cортировать",
          exitDelay: 100,
        });
      }
    },
  },
  props: {
    status: Object,
  },
  computed: {
    startDate(){
    return format(new Date(this.date.startDate), "dd-MM");
    },
    endDate(){
    return format(new Date(this.date.endDate), "dd-MM");
    }
  },
  methods: {
    check() {
      this.allChecked = !this.allChecked;
    },
    openFilter() {
      this.sort = !this.sort;
      this.toolTipFilter.close();
    },
    openSettings() {
      this.settings = !this.settings;
      this.toolTipSettings.close();
    },
    dropDown() {
      this.dropdown = !this.dropdown;
    },
    async filter(status) {
      if (status != 3) {
        const data = await this.$axios.post("lead/filter", { status: status });
        const filteredData = data.data;
        console.log(filteredData);
        this.$emit("filter", filteredData);
      } else {
        this.$emit("getAll");
      }
      this.dropdown = false;
    },
    showDeletedLeads() {},
    async getFilterDate() {
      let addDay = new Date();
      addDay = addDays(addDay, 3);
      await this.$axios.post("lead/filter/date", {
        startDate: new Date(),
        endDate: addDay,
      });
    },
    closeSubDropDown() {
      (this.filterStatus = false), (this.filterDate = false);
    },

    mountDatePicker() {
      this.startDatePicker = M.Datepicker.init(this.$refs.startDatePicker, {
        format: "dddd, dd-ое mmm.",
        defaultDate: new Date(),
        container: this.$refs.date_picker,
        setDefaultDate: false,
        autoClose: true,
        selectMonths: true,
        firstDay: 1,
        onSelect: (date) => {
          this.date.startDate = date;
        },
        i18n: {
          today: "Сегодня",
          clear: "Очистить",
          close: "Закрыть",
          cancel: "Закрыть",
          labelMonthNext: "Next month",
          labelMonthPrev: "Previous month",
          labelMonthSelect: "Select a month",
          labelYearSelect: "Select a year",
          months: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ],
          monthsShort: [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек",
          ],
          weekdays: [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
          ],
          // weekdaysShort: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
          weekdaysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
          weekdaysAbbrev: ["В", "П", "В", "С", "Ч", "П", "С"],
        },
        onDraw: () => {
          setTimeout(() => {
            const modal = document.getElementsByClassName("modal-overlay")[0];
            modal.classList.add("hide");
          });
        },
      });
      this.endDatePicker = M.Datepicker.init(this.$refs.endDatePicker, {
        format: "dddd, dd-ое mmm.",
        container: this.$refs.date_picker,
        defaultDate: new Date(),
        setDefaultDate: false,
        autoClose: true,
        selectMonths: false,
        firstDay: 1,
        onSelect: (date) => {
          this.date.endDate = date;
        },
        i18n: {
          today: "Сегодня",
          clear: "Очистить",
          close: "Закрыть",
          cancel: "Закрыть",
          labelMonthNext: "Next month",
          labelMonthPrev: "Previous month",
          labelMonthSelect: "Select a month",
          labelYearSelect: "Select a year",
          months: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
          ],
          monthsShort: [
            "Янв",
            "Фев",
            "Мар",
            "Апр",
            "Май",
            "Июн",
            "Июл",
            "Авг",
            "Сен",
            "Окт",
            "Ноя",
            "Дек",
          ],
          weekdays: [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
          ],
          // weekdaysShort: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
          weekdaysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
          weekdaysAbbrev: ["В", "П", "В", "С", "Ч", "П", "С"],
        },
        onDraw: () => {
          setTimeout(() => {
            const modal = document.getElementsByClassName("modal-overlay")[0];
            modal.classList.add("hide");
          });
        },
      });
    },
  },
};
</script>
<style scoped>
#header_wrp {
  z-index: 9;
}
#header_block {
  display: flex;
  align-items: center;
  transition: all 0.525s ease-in-out;
  will-change: contents;
}
#header_block span {
  font-weight: 700;
  margin: 0 auto;
  transition: all 0.525s ease-in-out;
}
i {
  font-size: 20px !important;
  transform: translateY(4px);
}

.white_space {
  width: 70px;
  transition: all 0.525s ease-in-out;
}
.settings_wrp,
.filter_wrp {
  cursor: pointer;
  top: 50%;
  position: absolute;
  right: 0;
  transition: all 0.525s ease-in-out;
  width: 260px;
  /* background: yellowgreen; */
  text-align: left;
}

.white_space i {
  cursor: pointer;
}
.is_active {
  transform: translateX(0) scale(1);
  opacity: 1;
}
.is_hidden {
  transform: translateX(300px) scale(0);
  opacity: 0;
}
.settings_wrp.normal {
  transform: translate(-50%, -50%) translateX(345px) scale(1);
}
.filter_wrp.normal {
  transform: translate(-50%, -50%) translateX(315px) scale(1);
}
.settings_wrp.is_moved {
  transform: translate(-50%, -50%) translateX(120px) scale(1);
}
.filter_wrp.is_moved {
  transform: translate(-50%, -50%) translateX(120px) scale(1);
}
.filter_settings,
.filter_menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-radius: 0 0 15px 15px;
  position: absolute;
  right: 26px;
  top: 46px;
  z-index: 5;
  width: 260px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: var(--black);
  backdrop-filter: blur(2px);
  padding-bottom: 15px;
}
.filter_link.active {
  border-bottom: 2px solid var(--m-yellow);
}
.settings_link {
  width: 100% !important;
  display: block !important;
}
#link_input {
  display: block !important;
  margin-left: 15px !important;
}

.settings_link,
.filter_link,
.filter_title {
  width: fit-content;
  margin: 0 auto;
  -webkit-font-smoothing: subpixel-antialiased;
  transform: perspective(0px);
  will-change: transform;
  backface-visibility: hidden;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: perspective(0.2px);
  transition: all 0.525s ease-in-out;
  border-bottom: 2px solid transparent;
}
.filter_title {
  font-size: 13px;
  font-weight: 900;
}
.filter_link:hover {
  transform: scale(1.1);
  border-bottom: 2px solid var(--m-blue);
}
.close {
  float: right;
  padding-right: 10px;
}
.hide {
  display: none !important;
  /* background: red!important; */
}
.icon_dropdow_return {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 10px;
}
.date_input{
  cursor: pointer;
}
</style>