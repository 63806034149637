<template>
  <div>
   <input :id="id"
          type="text"
          ref="timepicker"
          class="timepicker"
          v-model.lazy="inputData"/>
<label :for="id">{{label}}</label>

  </div>
</template>

<script>
import M from 'materialize-css' 

export default {
  name: 'formtimepicker',
   data() {
            return {
              inputData: '',
              time: ''
            }
        },
        mounted(){
          this.time = M.Timepicker.init(this.$refs.timepicker,{
            twelveHour: false,
            fromNow: 5000,
            duration: 50,
            showClearBtn: true,
             i18n: {
              cancel:	'Закрыть',
              clear:	'Очистить',
              done:	'Выбрать',
              am: 'пп'
              },
            onClose: ()=> {
              if(this.modal){
                setTimeout(()=>{
                  const body = document.getElementsByTagName('body')[0]
                  body.style.overflow='hidden'
                },1000)}
              else{
                const body = document.getElementsByTagName('body')[0]
                body.style.overflow=''
              }  
          }
            }
          )
          this.time.open()
          this.time.showView('hours')
        },
        watch:{
        inputData(newValue){
                if(this.inputData){
                this.$emit('inputData', newValue)
                }
          }
        },
        props:{
            label: String,
            id: String,
            modal: Boolean
        },
        computed:{},
        methods:{}
}
</script>

<style scoped>

</style>