
<template>
  <div class="body">
        <div class="header">
    <h2>заявка отправлена</h2>
    <i @click="$emit('closeThanks')"
    class="large material-icons close_btn">close</i>
    <!-- <i @click="$emit('closeForm')"  -->

  </div>
    <div class="icon">
        <i class="material-icons">done_all</i>
    </div>
  <p class="text">Cпасибо что обратились к нам <span class="name">{{person.name}}</span>! Ваша заявка отправлена, наши специалисты свяжутся с Вами по телефону: <span class="phone">{{person.phone}}</span>
    <span v-if="person.time || person.date"><br><br>Дата и время звонка: <span class="time">{{person.date}} в {{person.time}}</span></span>
    <span v-else> в ближайшее время!</span>
  </p> 
  <btn label="закрыть"
      class="master_btn"
      @sendForm="close"/> 
  </div>
</template>

<script>
import btn from './btn.vue'
export default {
        data() {
            return {
            }
        },
        name: 'thanks',
        props:{
          person: Object
        },
        components:{
            btn
        },
        computed: {

        },
        watch: {
            
        },
        methods: {
close(){
this.$emit('closeThanks')
}
        }
    
}
</script>
<style scoped>
.body{
    max-width: 500px;
}
.icon i {
    display: block;
    color: var(--m-white)!important;
    font-size: 50px!important;
    }
.header{
  display: flex;
}
h2{
  color: var(--m-white);
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  padding-left: 15px;
  width: 95%;
}
  h2::first-letter{
    text-transform: uppercase;
  }
  i{
    font-size: 25px!important;
    line-height: 100px;
    color: var(--m-white);
    cursor: pointer;
    transition: all .525s ease-in-out;
    transform: scale(1);
  }
  .close_btn:hover{
    color: var(--m-yellow);
    transform: scale(1.05);

  }
  .master_btn{
      color: var(--m-white)!important;
      text-align: center;
      margin: 50px auto;
  }

.text{
    text-align: left;
    color: var(--m-white);
    font-size: 18px;
    word-wrap:normal;
    white-space: pre-wrap;
}
.name{
    text-transform: capitalize;;
}
.time,
.phone{
    padding-bottom: 5px;
    border-bottom: 2px solid var(--m-yellow);
}
</style>
