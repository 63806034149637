
<template>
  <div class="body" :class="clientWidth < 1200 && clientWidth > 1100
        ? 'medium'
        : '' || (clientWidth < 1100 && clientWidth > 900)
        ? 'small'
        : '' || (clientWidth < 900 && clientWidth > 720)
        ? 'tiny'
        : '' || (clientWidth < 720 && clientWidth >670) 
        ? 'ultra_tiny'
        : '' || clientWidth < 670
        ? 'mobile'
        : ''
    ">
  <a name="advantages"></a>
    <h2>{{ title }}</h2>
    <div class="cont">
      <block
        :icons="{ stage_1: true }"
        :title="'Оперативность'"
        :text="'Подготовим и снарядим экипаж. Сможем организовать подачу техники уже на следующий день'"
        :icon="'add'"
        :clientWidth="clientWidth"
      />
      <block
        :icons="{ stage_2: true }"
        :title="'Собственная техника'"
        :text="'Вся техника новая 2020 г. Что позволяет выполнять работы быстро и качественно, без накладок'"
        :icon="'add'"
        :clientWidth="clientWidth"

      />
      <block
        :icons="{ stage_3: true }"
        :title="'Прозрачная смета'"
        :text="'Мы работаем по принципу аренды. Стоимость часовой работы экипажа на земснаряде фиксирована.'"
        :icon="'add'"
        :clientWidth="clientWidth"

      />
      <block
        :icons="{ stage_4: true }"
        :title="'Ответственность'"
        :text="'Не нужно заключать множество договоров с разными компаниями и разными условиями. Мы возьмём всё на себя!'"
        :icon="'add'"
        :clientWidth="clientWidth"
      />
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import block from "../block.vue";

export default {
  data() {
    return {};
  },
  name: "secadvantages",
  props: {
    title: String,
    clientWidth: Number,
  },
  mounted() {
    M.Collapsible.init(this.$refs.collapsible, {});
  },
  components: {
    block,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style scoped>
h2 {
  margin: 0;
  padding: 50px 0;
  color: var(--m-blue);
  font-size: 35px;
  font-weight: 900;
  text-transform: uppercase;
}
.cont {
  display: flex;
  flex-wrap: wrap;
  padding: 0 100px;
  padding-bottom: 50px;
}
.medium .cont,
.small .cont,
.tiny .cont ,
.ultra_tiny .cont{
  padding: 0 50px;
}
.mobile .cont{
  padding: 0 25px;
}
.mobile h2{
    font-size: 25px;
}
</style>