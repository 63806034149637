<template>
  <form class="col s8">
    <forminput
      label="Как вас зовут?"
      :type="'text'"
      :id="'name'"
      :mask="'ЯЯЯЯЯЯЯЯЯЯЯЯ'"
      :class="'input-field col s6'"
      @inputData="getName"
    />

    <forminput
      label="Телефон"
      :id="'phone'"
      :type="'tel'"
      :mask="'+7(9##)-###-##-##'"
      :class="'input-field col s6'"
      @inputData="getPhone"
      @click="phone = '+7(9666'"
    />

    <formtextarea
      label="Укажите комментарий"
      :id="'text'"
      :class="'input-field col s6'"
      @inputData="getText"
    />

    <formdatepicker
      label="Когда Вам удобно принять звонок?"
      :id="'date'"
      :class="'input-field col s6'"
      :modal="modal"
      @inputData="getDate"
    />

    <formtimepicker
      v-if="date"
      label="Время звонка"
      :id="'time'"
      :modal="modal"
      @inputData="getTime"
      :class="'input-field col s6'"
    />

    <btn
      label="оставить заявку"
      :ready="formCompleted"
      :class="{ disabled: !formCompleted }"
      class="align"
      @sendForm="sendForm"
    />
  </form>
</template>

<script>
import btn from "./btn.vue";
import formtimepicker from "./form/formtimepicker";
import formdatepicker from "./form/formdatepicker";
import formtextarea from "./form/formtextarea.vue";
import forminput from "./form/forminput.vue";

export default {
  name: "callbackform",
  data() {
    return {
      name: "",
      phone: "",
      text: "",
      date: "",
      time: "",
      stepOne: false,
      stepTwo: false,
      // ready: false
    };
  },
  components: {
    forminput,
    formtextarea,
    formdatepicker,
    formtimepicker,
    btn,
  },
  watch: {},
  computed: {
    formCompleted() {
      return this.stepOne && this.stepTwo;
    },
  },
  props: {
    modal: Boolean,
  },
  methods: {
    getName(inputData, ready) {
      this.name = inputData;
      this.stepOne = ready;
    },
    getPhone(inputData, ready) {
      this.phone = inputData;
      this.stepTwo = ready;
    },
    getText(inputData) {
      this.text = inputData;
    },
    getDate(inputData) {
      this.date = inputData;
    },
    getTime(inputData) {
      this.time = inputData;
    },
    sendForm() {
      this.$axios.post("lead/create", {
        name: this.name,
        phone: this.phone,
        text: this.text,
        date: this.date,
        time: this.time,
      });
    },
  },
};
</script>

<style scoped>
.master_btn {
  margin-top: 25px;
  border: 2px solid var(--m-yellow);
  color: var(--m-yellow) !important;
  /* border: 2px solid var(--m-white); */
  /* color: var(--m-white)!important; */
  transition: all 0.525s ease-in-out;
  cursor: pointer;
  backdrop-filter: blur(0px);
}
.master_btn:hover {
  box-shadow: -5px 0 10px 0 rgba(249, 204, 51, 0.2),
    5px 0 10px 0 rgba(249, 204, 51, 0.2), 0 5px 10px 0 rgba(249, 204, 51, 0.2),
    0 -5px 10px 0 rgba(249, 204, 51, 0.2);
  backdrop-filter: blur(2px);
}
.master_btn.disabled {
  border: 2px solid var(--m-white);
  color: var(--m-white) !important;
  /* backdrop-filter: blur(0px); */
}
.align {
  margin: 0 auto;
}
.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
  color: var(--m-blue) !important;
}
.select-wrapper input.select-dropdown {
  color: var(--m-blue) !important;
}
.datepicker-table td.is-today {
  color: var(--m-yellow) !important;
  /* background-color: rgba(10,20,31, .5)!important; */
}
.datepicker-table td.is-selected {
  background-color: var(--m-blue) !important;
  color: var(--m-yellow) !important;
}
.datepicker-calendar-container {
  background-image: var(--main);
  background-size: contain;
}
.input-field > label {
  font-size: 18px !important;
  color: var(--m-white) !important;
}
textarea.materialize-textarea {
  line-height: normal;
  color: var(--m-white);
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--m-yellow) !important;
  box-shadow: 0 1px 0 0 var(--m-yellow) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  --webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background-color: none !important;
  transition: background-color 500000s ease-in-out 0s !important;
  -webkit-text-fill-color: var(--m-white) !important;
  --webkit-background-clip: text;
  border-bottom: 2px solid var(--m-yellow) !important;
  border-left: none !important;
  border-right: none !important;
}
.datepicker-date-display {
  background-color: var(--m-blue) !important;
}
.timepicker-digital-display {
  background-color: var(--m-blue) !important;
  min-width: 190px !important;
  padding: 10px;
  font-weight: 300;
}
.timepicker-analog-display {
  background-image: var(--main);
  background-size: contain;
}
.timepicker-plate {
  background-color: #efefef !important;
  box-shadow: inset 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    inset 0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    inset 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 8.3px 7.9px rgba(10, 20, 31, 0.072),
    0 21.8px 13.4px rgba(10, 20, 31, 0.086), 0 15px 15px rgba(10, 20, 31, 0.12);
}
.timepicker-close {
  color: var(--m-blue) !important;
}
.timepicker-canvas line {
  stroke: var(--m-blue) !important;
  stroke-width: 4;
  stroke-linecap: round;
}
.timepicker-canvas-bg {
  stroke: none !important;
  stroke-width: 4;
  /* stroke: none; */
  fill: none !important;
}
.timepicker-canvas-bearing {
  fill: var(--m-blue) !important;
}
.timepicker-tick.active,
.timepicker-tick:hover {
  background-color: rgba(249, 204, 51, 0.5) !important;
}
.timepicker-tick {
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
  line-height: 40px;
  text-align: center;
  width: 40px;
  height: 40px;
  position: absolute;
  cursor: pointer;
  font-size: 15px;
}
</style>
