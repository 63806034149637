var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body",class:_vm.clientWidth < 1200 && _vm.clientWidth > 1100
      ? 'medium'
      : '' || (_vm.clientWidth < 1100 && _vm.clientWidth > 900)
      ? 'small'
      : '' || (_vm.clientWidth < 900 && _vm.clientWidth > 720)
      ? 'tiny'
      : '' || (_vm.clientWidth < 720 && _vm.clientWidth >670) 
      ? 'ultra_tiny'
      : '' || _vm.clientWidth < 670
      ? 'mobile'
      : ''},[_c('a',{attrs:{"name":"advantages"}}),_c('h2',[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"cont"},[_c('block',{attrs:{"icons":{ stage_1: true },"title":'Оперативность',"text":'Подготовим и снарядим экипаж. Сможем организовать подачу техники уже на следующий день',"icon":'add',"clientWidth":_vm.clientWidth}}),_c('block',{attrs:{"icons":{ stage_2: true },"title":'Собственная техника',"text":'Вся техника новая 2020 г. Что позволяет выполнять работы быстро и качественно, без накладок',"icon":'add',"clientWidth":_vm.clientWidth}}),_c('block',{attrs:{"icons":{ stage_3: true },"title":'Прозрачная смета',"text":'Мы работаем по принципу аренды. Стоимость часовой работы экипажа на земснаряде фиксирована.',"icon":'add',"clientWidth":_vm.clientWidth}}),_c('block',{attrs:{"icons":{ stage_4: true },"title":'Ответственность',"text":'Не нужно заключать множество договоров с разными компаниями и разными условиями. Мы возьмём всё на себя!',"icon":'add',"clientWidth":_vm.clientWidth}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }