
<template>
  <div
    class="cont"
    :class="
      clientWidth < 870 && clientWidth > 680
        ? 'tiny'
        : '' || clientWidth < 680
        ? 'ultra_tiny'
        : ''
    "
  >
    <div
      class="info_graphic"
      :class="
        blured
          ? 'blur'
          : '' || (clientWidth < 1200 && clientWidth > 1000)
          ? 'medium'
          : '' || (clientWidth < 1000 && clientWidth > 870)
          ? 'small'
          : '' || (clientWidth < 870 && clientWidth > 670)
          ? 'tiny'
          : '' || (clientWidth < 680 && clientWidth > 625)
          ? 'ultra_tiny'
          : '' || clientWidth < 625
          ? 'mobile'
          : ''
      "
      ref="svg"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="664.647"
        height="354.514"
        viewBox="0 0 664.647 354.514"
      >
        <g transform="translate(1.417 1.417)">
          <g id="gr_1" data-name="gr_1" transform="translate(25 103.25)">
            <path
              id="path"
              ref="path_1"
              data-name="path_1"
              d="M59.528,540.515c0,43.254-.067,153.094-.067,153.094l140.184,73.875"
              transform="translate(-54.666 -525.961)"
              fill="none"
              stroke="#f9cc33"
              stroke-miterlimit="10"
              stroke-width="2"
              stroke-dasharray="11.999"
            />
            <ellipse
              id="el"
              ref="el_1"
              data-name="Эллипс 5"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              fill="none"
              stroke="#f9cc33"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
            <!-- 2.835 -->
            <ellipse
              id="point"
              ref="point_1"
              @mouseover="
                showPopUp(
                  $refs.el_1,
                  $refs.pop_up_1,
                  $refs.path_1,
                  (isHover = true),
                  (active = hoverPoints)
                )
              "
              data-name="Эллипс 6"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(148.436 238.839)"
              fill="none"
              stroke="#f9cc33"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
          </g>
          <g id="gr_2" data-name="gr_2" transform="translate(230.681 105.098)">
            <path
              id="path"
              ref="path_2"
              data-name="Контур 2"
              d="M457.309,330.42h78.065V461.842"
              transform="translate(-441.54 -325.625)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2"
              stroke-dasharray="11.999"
            />
            <ellipse
              id="el"
              ref="el_2"
              data-name="Эллипс 1"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(0 0)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
            <ellipse
              id="point"
              ref="point_2"
              @mouseover="
                showPopUp(
                  $refs.el_2,
                  $refs.pop_up_2,
                  $refs.path_2,
                  (isHover = true),
                  (active = hoverPoints)
                )
              "
              data-name="Эллипс 7"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(88.753 138.478)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
          </g>
          <g id="gr_3" data-name="gr_3" transform="translate(385.664)">
            <ellipse
              id="el"
              ref="el_3"
              data-name="Эллипс 3"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              fill="none"
              stroke="#f9cc33"
              stroke-miterlimit="10"
              stroke-width="2.835"
              transform="translate(40 30)"
            />
            <ellipse
              id="point"
              ref="point_3"
              @mouseover="
                showPopUp(
                  $refs.el_3,
                  $refs.pop_up_3,
                  $refs.path_3,
                  (isHover = true),
                  (active = hoverPoints)
                )
              "
              data-name="Эллипс 10"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(40 196.839)"
              fill="none"
              stroke="#f9cc33"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
            <path
              id="path"
              ref="path_3"
              data-name="Контур 5"
              d="M751.747,517.57V369.932"
              transform="translate(-706.952 -324.83)"
              fill="none"
              stroke="#f9cc33"
              stroke-miterlimit="10"
              stroke-width="2"
              stroke-dasharray="11.999"
            />
          </g>
          <g id="gr_4" data-name="gr_4" transform="translate(390.336 125.164)">
            <path
              id="path"
              ref="path_4"
              data-name="Контур 6"
              d="M346.407,543.375,144.986,657.523"
              transform="translate(-140.191 -533.784)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2"
              stroke-dasharray="11.999"
            />
            <ellipse
              id="el"
              ref="el_4"
              data-name="el_4"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(210.723)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
            <ellipse
              id="point"
              ref="point_4"
              @mouseover="
                showPopUp(
                  $refs.el_4,
                  $refs.pop_up_4,
                  $refs.path_4,
                  (isHover = true),
                  (active = hoverPoints)
                )
              "
              data-name="Эллипс 11"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(0 118.943)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
          </g>
          <g id="gr_5" data-name="gr_5" transform="translate(421.898 201.377)">
            <path
              id="path"
              ref="path_5"
              data-name="Контур 6"
              d="M365.43,543.375,138.1,662.082"
              transform="translate(-139.5 -543.375)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2"
              stroke-dasharray="11.999"
            />

            <ellipse
              id="point"
              ref="point_5"
              @mouseover="
                showPopUp(
                  $refs.el_5,
                  $refs.pop_up_5,
                  $refs.path_5,
                  (isHover = true),
                  (active = hoverPoints)
                )
              "
              data-name="Эллипс 11"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(-12 115.735)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
            <ellipse
              id="el"
              ref="el_5"
              data-name="Эллипс 2"
              cx="4.795"
              cy="4.795"
              rx="4.795"
              ry="4.795"
              transform="translate(230 -10)"
              fill="none"
              stroke="#fff6c9"
              stroke-miterlimit="10"
              stroke-width="2.835"
            />
          </g>
        </g>
      </svg>
    </div>
    <div
      class="pop_ups"
      :class="
        clientWidth < 680 && clientWidth > 625
          ? 'ultra_tiny'
          : '' || clientWidth < 625
          ? 'mobile'
          : ''
      "
    >
      <div class="svg_popup" ref="pop_up_1">
        очистка любых водоёмов<br />глубиной до 4-х метров
      </div>
      <div class="svg_popup" ref="pop_up_2">
        почасовая аренда<br />техники с проф. экипажем
      </div>
      <div class="svg_popup" ref="pop_up_3">
        удаление всех<br />донных отложений
      </div>
      <div class="svg_popup" ref="pop_up_4">новая техника<br />2020 года</div>
      <div class="svg_popup" ref="pop_up_5">
        не нарушаем флору<br />и фауну водоёма
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoverPoints: false,
      blured: true,
      slider: {
        slides: [],
        currentSlideNumber: 0,
        interval: 0,
      },
    };
  },
  mounted() {
    const slide1 = {
      visibilty: false,
      elArr: [this.$refs.el_1, this.$refs.el_3, this.$refs.el_5],
      pathArr: [this.$refs.path_1, this.$refs.path_3, this.$refs.path_5],
      popUpArr: [this.$refs.pop_up_1, this.$refs.pop_up_3, this.$refs.pop_up_5],
      pointArr: [this.$refs.point_1, this.$refs.point_3, this.$refs.point_5],
    };
    const slide2 = {
      visibilty: false,
      elArr: [this.$refs.el_2, this.$refs.el_4],
      pathArr: [this.$refs.path_2, this.$refs.path_4],
      popUpArr: [this.$refs.pop_up_2, this.$refs.pop_up_4],
      pointArr: [this.$refs.point_2, this.$refs.point_4],
    };
    this.slider.slides = [slide1, slide2];

    this.slideShow();
  },

  created() {
    window.addEventListener("resize", this.showPopUp);
    window.addEventListener("resize", this.strartSlide1);
  },
  name: "infographic",
  props: { scrollY: Number, clientWidth: Number },
  components: {},
  computed: {},
  watch: {},
  methods: {
    showPopUp(el, ref, path, hover, active) {
      if (active) {
        const domRectEl = el.getBoundingClientRect();
        const domRectRef = ref.getBoundingClientRect();
        const height = domRectEl.bottom - domRectRef.height - 15;
        ref.classList.add("show");
        path.classList.add("show");
        el.classList.add("show");
        ref.style.top = height + "px";
        ref.style.left = domRectEl.left + "px";
      }
    },
    slideShow() {
      const self = this;
      setTimeout(() => {
        self.slider.interval = setInterval(self.startSlide, 1500);
      }, 700);
      this.blured = false;
    },

    startSlide() {
      let currentSlide = this.slider.slides[this.slider.currentSlideNumber];
      this.slider.slides.forEach((slide) => {
        slide.elArr.forEach((el, i) => {
          el.classList.remove("show");
          slide.popUpArr[i].classList.remove("show");
          slide.pathArr[i].classList.remove("show");
          slide.pointArr[i].classList.remove("show");
        });
      });
      currentSlide.elArr.forEach((el, i) => {
        //    getting client position values
        const domRectEl = el.getBoundingClientRect();
        const domRectRef = currentSlide.popUpArr[i].getBoundingClientRect();
        const height = domRectRef.height + 10;
        const x = domRectEl.x;
        const y = domRectEl.y + this.scrollY - height;
        currentSlide.popUpArr[i].style.transform = `translate(${x}px,${y}px)`;
        //   adding class 'show'
        currentSlide.popUpArr[i].classList.add("show");
        el.classList.add("show");
        currentSlide.pathArr[i].classList.add("show");
        currentSlide.pointArr[i].classList.add("show");
      });
      this.slider.currentSlideNumber++;

      if (this.slider.currentSlideNumber >= this.slider.slides.length) {
        this.slider.currentSlideNumber = 0;
      }
    },
  },
};
</script>
<style scoped>
.cont {
  display: flex;
  margin-top: 130px;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
}
.info_graphic {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 700px;
  height: 600px;
  z-index: 9;
  /* bottom: 0;
  left: 50%;
  transform: translate(-50%, 5%);*/
  transition: all 1.25s ease-in-out;
  filter: blur(0);
  position: absolute;
  background-image: url("./../assets/img/hero_asset_2.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
svg {
  margin: 0 auto;
}
.svg_popup {
  text-align: left;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.525s ease-in-out;
  color: var(--m-white);
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  filter: blur(100px);
}
.svg_popup::first-letter {
  text-transform: capitalize;
}
#el,
#path,
#point {
  transition: all 0.725s ease-in-out;
  opacity: 0;
  filter: blur(1px);
}
.show {
  opacity: 1 !important;
  filter: blur(0) !important;
}
.blur {
  filter: blur(63px);
}
.medium {
  width: 600px;
  height: 500px;
}
.small {
  /* transform: translate(-50%, 0%); */
  width: 600px;
  height: 500px;
}
.small svg path {
  stroke-width: 2.835;
}
.mobile svg path,
.mobile svg ellipse {
  stroke-width: 3.835;
}

.cont.tiny {
  margin-top: 100px;
}
.cont.ultra_tiny {
  margin-top: 100px;
}
.info_graphic.tiny {
  width: 500px;
  height: 450px;
}
.info_graphic.ultra_tiny {
  width: 450px;
  height: 350px;
}
.info_graphic.mobile {
  width: 450px;
  height: 350px;
}
.tiny #gr_5 {
  transform: translate(321.898px, 221.377px) !important;
  /* display: none; */
}
.mobile #gr_1 {
  transform: translate(5px, 153.25px);
}

.pop_ups.ultra_tiny div {
  font-size: 14px !important;
}
.pop_ups.mobile div {
  font-size: 12px !important;
  line-height: 12px;
}
.ultra_tiny svg {
  padding: 50px;
}
.mobile svg {
  padding: 75px;
}
</style>