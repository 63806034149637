
<template>
  <div>
  <modal>    
  <form class="col s8">
    <forminput
      label="Логин"
      :type="'text'"
      :id="'login'"
      :class="'input-field col s6'"
      @inputData="getLog"
    />
    <forminput
      label="Пароль"
      :type="'password'"
      :id="'password'"
      :class="'input-field col s6'"
      @inputData="getPass"
    />
    <btn
    class="master_btn"
      label="войти"
      @sendForm="logIn"
    />

  </form>
  </modal>
  </div>
</template>

<script>
import forminput from '../components/form/forminput'
import modal from '../components/modal'
import btn from '../components/btn'

export default {
  data() {
    return {
        login:'',
        password: '',
        admin: false
    };
  },
  name: "admin",
  props: {},
  components: {
      forminput,
      modal,
      btn
  },
  computed: {},
  watch: {},
  methods: {
      getLog(inputData){
      this.login = inputData
      },
      getPass(inputData){
      this.password = inputData
      },
      async logIn(){
        const login = await this.$axios.post("user/login", { username: this.login, password: this.password })
        // const isAuth = await this.$axios.post("user/testAut")
        if (login.data.username) {
        this.admin = true
        this.$router.push({path: `/dashboard`}) 
      }

      }
  },
};
</script>
<style scoped>
.master_btn{
    color: var(--m-white)!important;
    margin: 50px auto!important;
}
</style>