var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block",class:_vm.clientWidth < 1200 && _vm.clientWidth > 1100
    ? 'medium'
    : '' || (_vm.clientWidth < 1100 && _vm.clientWidth > 900)
    ? 'small'
    : '' || (_vm.clientWidth < 900 && _vm.clientWidth > 720)
    ? 'tiny'
    : '' || (_vm.clientWidth < 720 && _vm.clientWidth >670) 
    ? 'ultra_tiny'
    : '' || _vm.clientWidth < 670
    ? 'mobile'
    : ''},[(_vm.icons.stage_1)?_c('div',{staticClass:"icon stage_1"}):_vm._e(),(_vm.icons.stage_2)?_c('div',{staticClass:"icon stage_2"}):_vm._e(),(_vm.icons.stage_3)?_c('div',{staticClass:"icon stage_3"}):_vm._e(),(_vm.icons.stage_4)?_c('div',{staticClass:"icon stage_4"}):_vm._e(),_c('ul',{ref:"collapsible",staticClass:"collapsible"},[_c('li',[_c('div',{staticClass:"collapsible-header"},[_c('h3',[_vm._v(_vm._s(_vm.title))]),_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.icon))])]),_c('div',{staticClass:"collapsible-body"},[_c('span',[_c('p',[_vm._v(_vm._s(_vm.text))])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }