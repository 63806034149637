var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cont",class:_vm.clientWidth < 870 && _vm.clientWidth > 680
      ? 'tiny'
      : '' || _vm.clientWidth < 680
      ? 'ultra_tiny'
      : ''},[_c('div',{ref:"svg",staticClass:"info_graphic",class:_vm.blured
        ? 'blur'
        : '' || (_vm.clientWidth < 1200 && _vm.clientWidth > 1000)
        ? 'medium'
        : '' || (_vm.clientWidth < 1000 && _vm.clientWidth > 870)
        ? 'small'
        : '' || (_vm.clientWidth < 870 && _vm.clientWidth > 670)
        ? 'tiny'
        : '' || (_vm.clientWidth < 680 && _vm.clientWidth > 625)
        ? 'ultra_tiny'
        : '' || _vm.clientWidth < 625
        ? 'mobile'
        : ''},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"664.647","height":"354.514","viewBox":"0 0 664.647 354.514"}},[_c('g',{attrs:{"transform":"translate(1.417 1.417)"}},[_c('g',{attrs:{"id":"gr_1","data-name":"gr_1","transform":"translate(25 103.25)"}},[_c('path',{ref:"path_1",attrs:{"id":"path","data-name":"path_1","d":"M59.528,540.515c0,43.254-.067,153.094-.067,153.094l140.184,73.875","transform":"translate(-54.666 -525.961)","fill":"none","stroke":"#f9cc33","stroke-miterlimit":"10","stroke-width":"2","stroke-dasharray":"11.999"}}),_c('ellipse',{ref:"el_1",attrs:{"id":"el","data-name":"Эллипс 5","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","fill":"none","stroke":"#f9cc33","stroke-miterlimit":"10","stroke-width":"2.835"}}),_c('ellipse',{ref:"point_1",attrs:{"id":"point","data-name":"Эллипс 6","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(148.436 238.839)","fill":"none","stroke":"#f9cc33","stroke-miterlimit":"10","stroke-width":"2.835"},on:{"mouseover":function($event){_vm.showPopUp(
                _vm.$refs.el_1,
                _vm.$refs.pop_up_1,
                _vm.$refs.path_1,
                (_vm.isHover = true),
                (_vm.active = _vm.hoverPoints)
              )}}})]),_c('g',{attrs:{"id":"gr_2","data-name":"gr_2","transform":"translate(230.681 105.098)"}},[_c('path',{ref:"path_2",attrs:{"id":"path","data-name":"Контур 2","d":"M457.309,330.42h78.065V461.842","transform":"translate(-441.54 -325.625)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2","stroke-dasharray":"11.999"}}),_c('ellipse',{ref:"el_2",attrs:{"id":"el","data-name":"Эллипс 1","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(0 0)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2.835"}}),_c('ellipse',{ref:"point_2",attrs:{"id":"point","data-name":"Эллипс 7","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(88.753 138.478)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2.835"},on:{"mouseover":function($event){_vm.showPopUp(
                _vm.$refs.el_2,
                _vm.$refs.pop_up_2,
                _vm.$refs.path_2,
                (_vm.isHover = true),
                (_vm.active = _vm.hoverPoints)
              )}}})]),_c('g',{attrs:{"id":"gr_3","data-name":"gr_3","transform":"translate(385.664)"}},[_c('ellipse',{ref:"el_3",attrs:{"id":"el","data-name":"Эллипс 3","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","fill":"none","stroke":"#f9cc33","stroke-miterlimit":"10","stroke-width":"2.835","transform":"translate(40 30)"}}),_c('ellipse',{ref:"point_3",attrs:{"id":"point","data-name":"Эллипс 10","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(40 196.839)","fill":"none","stroke":"#f9cc33","stroke-miterlimit":"10","stroke-width":"2.835"},on:{"mouseover":function($event){_vm.showPopUp(
                _vm.$refs.el_3,
                _vm.$refs.pop_up_3,
                _vm.$refs.path_3,
                (_vm.isHover = true),
                (_vm.active = _vm.hoverPoints)
              )}}}),_c('path',{ref:"path_3",attrs:{"id":"path","data-name":"Контур 5","d":"M751.747,517.57V369.932","transform":"translate(-706.952 -324.83)","fill":"none","stroke":"#f9cc33","stroke-miterlimit":"10","stroke-width":"2","stroke-dasharray":"11.999"}})]),_c('g',{attrs:{"id":"gr_4","data-name":"gr_4","transform":"translate(390.336 125.164)"}},[_c('path',{ref:"path_4",attrs:{"id":"path","data-name":"Контур 6","d":"M346.407,543.375,144.986,657.523","transform":"translate(-140.191 -533.784)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2","stroke-dasharray":"11.999"}}),_c('ellipse',{ref:"el_4",attrs:{"id":"el","data-name":"el_4","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(210.723)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2.835"}}),_c('ellipse',{ref:"point_4",attrs:{"id":"point","data-name":"Эллипс 11","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(0 118.943)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2.835"},on:{"mouseover":function($event){_vm.showPopUp(
                _vm.$refs.el_4,
                _vm.$refs.pop_up_4,
                _vm.$refs.path_4,
                (_vm.isHover = true),
                (_vm.active = _vm.hoverPoints)
              )}}})]),_c('g',{attrs:{"id":"gr_5","data-name":"gr_5","transform":"translate(421.898 201.377)"}},[_c('path',{ref:"path_5",attrs:{"id":"path","data-name":"Контур 6","d":"M365.43,543.375,138.1,662.082","transform":"translate(-139.5 -543.375)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2","stroke-dasharray":"11.999"}}),_c('ellipse',{ref:"point_5",attrs:{"id":"point","data-name":"Эллипс 11","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(-12 115.735)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2.835"},on:{"mouseover":function($event){_vm.showPopUp(
                _vm.$refs.el_5,
                _vm.$refs.pop_up_5,
                _vm.$refs.path_5,
                (_vm.isHover = true),
                (_vm.active = _vm.hoverPoints)
              )}}}),_c('ellipse',{ref:"el_5",attrs:{"id":"el","data-name":"Эллипс 2","cx":"4.795","cy":"4.795","rx":"4.795","ry":"4.795","transform":"translate(230 -10)","fill":"none","stroke":"#fff6c9","stroke-miterlimit":"10","stroke-width":"2.835"}})])])])]),_c('div',{staticClass:"pop_ups",class:_vm.clientWidth < 680 && _vm.clientWidth > 625
        ? 'ultra_tiny'
        : '' || _vm.clientWidth < 625
        ? 'mobile'
        : ''},[_c('div',{ref:"pop_up_1",staticClass:"svg_popup"},[_vm._v(" очистка любых водоёмов"),_c('br'),_vm._v("глубиной до 4-х метров ")]),_c('div',{ref:"pop_up_2",staticClass:"svg_popup"},[_vm._v(" почасовая аренда"),_c('br'),_vm._v("техники с проф. экипажем ")]),_c('div',{ref:"pop_up_3",staticClass:"svg_popup"},[_vm._v(" удаление всех"),_c('br'),_vm._v("донных отложений ")]),_c('div',{ref:"pop_up_4",staticClass:"svg_popup"},[_vm._v("новая техника"),_c('br'),_vm._v("2020 года")]),_c('div',{ref:"pop_up_5",staticClass:"svg_popup"},[_vm._v(" не нарушаем флору"),_c('br'),_vm._v("и фауну водоёма ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }