<template>
  <li
    class="lead_block"
    :class="
      lead.status == 0 ? 'new_lead' : '' || lead.status == 2 ? 'is_deleted' : ''
    "
    ref="lead_block"
  >
    <!-- input -->
    <leadcheck @inputData="getCheckBox" :checkBox="checkBox" />

    <!-- # number -->
    <span class="">#{{ i + 1 }}</span>

    <!-- status  -->
    <leadstatus :status="status" :getAll="getAll" :lead="lead" :i="i" :openStatusMenu="openStatusMenu"/>
    <!-- name -->
    <span class="name">
      <div v-if="!editObject">{{ lead.name }}</div>

      <div v-else>
        <input class="input-field edit_input" v-model="newName" />
      </div>
    </span>
    <!-- text  -->
    <span class="">
      <a
        class="waves-effect waves-teal btn-flat"
        v-if="lead.text"
        data-position="top"
        ref="tooltippedText"
      >
        <i class="material-icons left">comment</i>
      </a>
      <a class="waves-effect waves-teal btn-flat disabled" v-else>
        <i class="material-icons left">remove</i>
        <!-- — -->
      </a>
    </span>
    <!-- phone -->
    <span class="">
      <div v-if="!editObject">{{ lead.phone }}</div>

      <div v-else>
        <input class="input-field edit_input" v-model="newPhone" />
      </div>
    </span>

    <!-- time -->
    <span class=""> {{ callBackDate }} в {{ lead.time }} </span>

    <div v-if="lead.status != 2">
      <span v-if="!editObject">
        <!-- edit -->
        <a
          class="waves-effect waves-teal btn-flat master"
          @click="editObject = !editObject"
          data-position="right"
          data-tooltip="изменить"
          ref="tooltippedEdit"
        >
          <i class="material-icons left">edit</i>
        </a>

        <!-- delete -->
        <a
          class="waves-effect waves-teal btn-flat master"
          @click="softDelete"
          data-position="right"
          data-tooltip="удалить"
          ref="tooltippedDelete"
        >
          <i class="material-icons left">delete_forever</i>
          <!-- удалить -->
        </a>
      </span>

      <!-- click edit -->
      <span v-else>
        <!-- update -->
        <a
          class="waves-effect waves-teal btn-flat master"
          @click="edit"
          data-position="right"
          data-tooltip="сохранить"
          ref="tooltippedUpdate"
        >
          <i class="material-icons left">save</i>
          <!-- обновить -->
        </a>
        <!-- cancel -->
        <a
          class="waves-effect waves-teal btn-flat master"
          @click="editObject = !editObject"
          data-position="right"
          data-tooltip="закрыть"
          ref="tooltippedCancel"
        >
          <i class="material-icons left">cancel</i>
          <!-- закрыть -->
        </a>
      </span>
    </div>
    <div v-else class="restore">
      <span>
        <a class="white_space"></a>

        <!-- restore -->
        <a
        @click="restore"
          class="waves-effect waves-teal btn-flat master"
          data-position="right"
          data-tooltip="восстановить"
          ref="tooltippedRestore"
        >
          <i class="material-icons left">restore</i>
        </a>

      </span>
    </div>
  </li>
</template>

<script>
import { format } from "date-fns";
import leadcheck from "./lead/leadcheck.vue";
import leadstatus from "./lead/leadstatus.vue";
// import leadselect from './lead/leadselect.vue'

import M from "materialize-css";

export default {
  data() {
    return {
      openStatusMenu: false,
      editObject: false,
      newName: "",
      newPhone: "",
      checkBox: false,
      date: "",
      newOrder: false,
    };
  },
  name: "Lead",
  components: {
    leadcheck,
    leadstatus,
    // leadselect
  },
  props: {
    i: Number,
    lead: Object,
    data: Array,
    getAll: Function,
    checkedAll: Boolean,
  },
  created() {},
  mounted() {
    (this.toolTipEdit = M.Tooltip.init(this.$refs.tooltippedEdit, {})),
      (this.toolTipDelete = M.Tooltip.init(this.$refs.tooltippedDelete, {})),
      (this.toolTipUdate = M.Tooltip.init(this.$refs.tooltippedUpdate, {})),
      (this.toolTipCancel = M.Tooltip.init(this.$refs.tooltippedCancel, {})),
      (this.toolTipRestore = M.Tooltip.init(this.$refs.tooltippedRestore, {})),
      (this.toolTipText = M.Tooltip.init(this.$refs.tooltippedText, {
        html: `комментарий: ${this.lead.text}`,
      }));
  },
  computed: {
    createdDate() {
      return format(new Date(this.lead.created), "yyyy-MM-dd");
    },
    callBackDate(){
      return format(new Date(this.lead.date), "yyyy-MM-dd");
    },
    status() {
      let status = {};
      if (this.lead.status == 0) {
        status.text = "новая";
        status.class = "light-green accent-2";
      }
      if (this.lead.status == 1) {
        status.text = "в работе";
        status.class = "indigo lighten-3";
      }
      if (this.lead.status == 2) {
        status.text = "уаленно";
        status.class = "red lighten-3";

      }
      this.$emit("status", status);
      return status;
    },
  },
  watch: {
    checkedAll(newValue) {
      this.checkBox = newValue;
    },
    checked(newValue) {
      this.checkBox = newValue;
    },
    editObject(newValue) {
      if (newValue) {
        this.toolTipEdit.close();
        this.toolTipDelete.close();
      }
      this.newName = this.lead.name;
      this.newPhone = this.lead.phone;
      console.log(newValue);
    },
  },
  methods: {
    getId() {
      console.log("t");
      this.leadId = this.lead._id;
    },
    getCheckBox(inputData) {
      this.checkBox = inputData;
    },
    async edit() {
      this.lead.name = this.newName;
      this.lead.phone = this.newPhone;
      await this.$axios.post("lead/edit", {
        id: this.lead._id,
        name: this.lead.name,
        phone: this.lead.phone,
      });
      this.editObject = !this.editObject;
      this.getAll();
    },
    async softDelete() {
      M.toast({ html: `Заявка №${this.i + 1} удалена` });
      await this.$axios.post("lead/delete", {
        id: this.lead._id,
      });
      this.toolTipDelete.destroy();
      this.getAll();
    },
    restore(){
      this.$refs.lead_block.classList.remove('is_deleted')
      this.$refs.lead_block.classList.add('is_restored')
      this.openStatusMenu = true
    },
  },
};
</script>

<style scoped>
.material-tooltip {
  font-size: 10px !important;
}
span.badge {
  line-height: 26px;
}
.name {
  width: 100px;
}
.edit_input {
  text-align: center;
  margin-left: 5px;
  min-width: 100px !important;
  height: 1.3em !important;
  font-size: 14px !important;
  color: #000000de !important;
  padding-bottom: 2px !important;
  margin-top: 10px !important;
}
.material-icons {
  font-size: 18px !important;
  margin-left: 10px !important;
  margin-right: unset !important;
}
.btn-flat {
  font-size: 10px !important;
  height: unset !important;
  line-height: unset !important;
}
.lead_block {
  background: rgb(239, 239, 239) !important;
  display: flex;
  align-items: center;
}
.is_restored{
  pointer-events: all;
  opacity: 1;
}
.new_lead {
  background: rgb(255, 255, 255) !important;
}
.is_deleted {
  background: rgba(255, 0, 0, 0.07) !important;
  pointer-events: none;
  opacity: 0.7;
}
.lead_block span:nth-child(2) {
  padding-left: 30px;
  /* margin: 0 auto; */
}
.lead_block span {
  margin: 0 auto;
}
.collection-item {
  height: unset !important;
  height: 45px !important;
}
[type="checkbox"] + span:not(.lever):before,
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  width: 14px !important;
  height: 14px !important;
  top: 13px !important;
}
.input {
  position: absolute;
  transform: translateY(-10px);
}
.master {
  padding: 0 5px !important;
}
.white_space {
  display: inline-block;
  width: 38px;
  height: 21px;
}
.restore{
  transition: all .525s ease-in-out;
  pointer-events: all;
}
.restore:hover{
  opacity: 1;
}
</style>