<template>
  <form class="col s8">
    <div class="header">
      <h2>введите данные</h2>
      <i @click="$emit('closeForm')" class="large material-icons close_btn"
        >close</i
      >
      <!-- <i @click="$emit('closeForm')"  -->
    </div>
    <forminput
      label="Как вас зовут?"
      :type="'text'"
      :id="'name'"
      :mask="'ЯЯЯЯЯЯЯЯЯЯЯЯ'"
      :class="'input-field col s12'"
      @inputData="getName"
    />

    <forminput
      label="Телефон"
      :id="'phone'"
      :type="'tel'"
      :mask="'+7(9##)-###-##-##'"
      :class="'input-field col s12'"
      @inputData="getPhone"
      @click="phone = '+7(9666'"
    />

    <formtextarea
      label="Укажите комментарий"
      :id="'text'"
      :class="'input-field col s12'"
      @inputData="getText"
    />

    <formdatepicker
      label="Когда Вам удобно принять звонок?"
      :id="'date'"
      :class="'input-field col s12'"
      :modal="modal"
      @inputData="getDate"
    />

    <formtimepicker
      v-if="date"
      label="Когда Вам удобно принять звонок?"
      :id="'date'"
      :modal="modal"
      @inputData="getTime"
      :class="'input-field col s12'"
    />

    <btn
      label="оставить заявку"
      :ready="formCompleted"
      :class="{ disabled: !formCompleted }"
      class="align"
      @sendForm="sendForm"
    />
  </form>
</template>

<script>
import btn from "./btn.vue";
import formdatepicker from "./form/formdatepicker";
import formtimepicker from "./form/formtimepicker";
import formtextarea from "./form/formtextarea.vue";
import forminput from "./form/forminput.vue";

export default {
  name: "Form",
  data() {
    return {
      time: "",
      name: "",
      phone: "",
      text: "",
      date: "",
      stepOne: false,
      stepTwo: false,
      // ready: false
    };
  },
  props: {
    modal: Boolean,
  },
  components: {
    forminput,
    formtextarea,
    formdatepicker,
    btn,
    formtimepicker,
  },
  watch: {},
  computed: {
    formCompleted() {
      return this.stepOne && this.stepTwo;
    },
  },
  methods: {
    getName(inputData, ready) {
      this.name = inputData;
      this.stepOne = ready;
    },
    getPhone(inputData, ready) {
      this.phone = inputData;
      this.stepTwo = ready;
    },
    getText(inputData) {
      this.text = inputData;
    },
    getDate(inputData) {
      this.date = inputData;
    },
    getTime(inputData) {
      this.time = inputData;
    },
    async sendForm() {
      const person = {
        name: this.name,
        phone: this.phone,
        text: this.text,
        date: this.date,
        time: this.time,
      };
      const req = await this.$axios.post("lead/create", person);
      if (req.data) {
        this.$emit("openThanks", person);
        this.$emit("closeForm");
        if (this.$cookies.get("guest_id")) {
          await this.$axios.post("lead/complete", {
            id: this.$cookies.get("guest_id"),
            name: this.name,
            phone: this.phone,
          });
        }
      }

      // this.$emit(this.person)
      // this.$emit('closeForm')
    },
    test() {
      console.log("yes");
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
}
h2 {
  color: var(--m-white);
  font-size: 25px;
  text-align: left;
  font-weight: 700;
  padding-left: 15px;
  width: 95%;
}
h2::first-letter {
  text-transform: uppercase;
}
i {
  font-size: 25px !important;
  line-height: 100px;
  color: var(--m-white);
  cursor: pointer;
  transition: all 0.525s ease-in-out;
  transform: scale(1);
}
.close_btn:hover {
  color: var(--m-yellow);
  transform: scale(1.05);
}

.master_btn {
  margin-top: 50px;
  border: 2px solid var(--m-yellow);
  color: var(--m-yellow) !important;
  transition: all 0.525s ease-in-out;
  cursor: pointer;
}
.master_btn:hover {
  box-shadow: -5px 0 10px 0 rgba(249, 204, 51, 0.2),
    5px 0 10px 0 rgba(249, 204, 51, 0.2), 0 5px 10px 0 rgba(249, 204, 51, 0.2),
    0 -5px 10px 0 rgba(249, 204, 51, 0.2);
}
.master_btn.disabled {
  border: 2px solid var(--m-white);
  color: var(--m-white) !important;
}
.align {
  margin: 50px auto;
}

.datepicker-cancel,
.datepicker-clear,
.datepicker-today,
.datepicker-done {
  color: var(--m-blue) !important;
}
.select-wrapper input.select-dropdown {
  color: var(--m-blue) !important;
}
.datepicker-table td.is-today {
  color: var(--m-yellow) !important;
  /* background-color: rgba(10,20,31, .5)!important; */
}
.datepicker-table td.is-selected {
  background-color: var(--m-blue) !important;
  color: var(--m-yellow) !important;
}
.datepicker-calendar-container {
  background-image: var(--main);
  background-size: contain;
}
.input-field > label {
  font-size: 18px !important;
  color: var(--m-white) !important;
}
textarea.materialize-textarea {
  line-height: normal;
  color: var(--m-white);
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--m-yellow) !important;
  box-shadow: 0 1px 0 0 var(--m-yellow) !important;
}
input:-internal-autofill-selected {
    appearance: menulist-button;
    background-color: transparent !important;
    }
</style>
