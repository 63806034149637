<template>
  <section
    class="body"
    :class="
      clientWidth < 870 && clientWidth > 670
        ? 'tiny'
        : '' || clientWidth < 670
        ? 'ultra_tiny'
        : ''
    "
  >
    <div
      class="preview"
      :class="
        clientWidth < 1000 && clientWidth > 870
          ? 'medium'
          : '' || (clientWidth < 870 && clientWidth > 670)
          ? 'tiny'
          : '' || clientWidth < 670
          ? 'ultra_tiny'
          : ''
      "
      ref="preview"
    >
      <div class="title">
        <h1>Очистка водоемов любой сложности</h1>
        <h2>Дноуглубление, cтроительство, ландшафтные работы</h2>
        <btn
          class="hero_btn"
          :label="'оставить заявку'"
          @sendForm="$emit('openModal')"
        />
      </div>
    </div>
    <infographic :scrollY="scrollY" :clientWidth="clientWidth" />
  </section>
</template>

<script>
import btn from "../btn.vue";
import infographic from "../infographic.vue";
export default {
  name: "hero",
  data() {
    return {};
  },
  mounted() {},
  created() {},
  components: {
    btn,
    infographic,
  },
  watch: {},
  props: {
    scrollY: Number,
    clientWidth: Number,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
.preview {
  height: 615px;
  width: 100%;
  background: var(--m-blue);
}
.body {
  min-height: 720px;
  /* height: 100vh; */
  min-width: 100vw;
}
h1 {
  font-size: 35px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  margin: 0;
}
h2 {
  margin: 0;
  text-align: left;
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 50px;
}
.title {
  position: absolute;
  top: 170px;
  left: 100px;
  color: var(--m-white);
  z-index: 15;
}
.hero_btn {
  transition: all 0.525s ease-in-out;
}
.hero_btn:hover {
  box-shadow: -5px 0 10px 0 rgba(249, 204, 51, 0.2),
    5px 0 10px 0 rgba(249, 204, 51, 0.2), 0 5px 10px 0 rgba(249, 204, 51, 0.2),
    0 -5px 10px 0 rgba(249, 204, 51, 0.2);
  color: var(--m-yellow);
  border: 2px solid var(--m-yellow);
}
.medium {
  height: 675px;
}
.medium h1 {
  font-size: 30px;
}
.medium h2 {
  font-size: 20px;
}
.medium.preview {
  height: 600px !important;
}
.body.ultra_tiny {
  min-height: 500px !important;
}
.body.tiny {
  min-height: 700px !important;
}
.tiny .hero_btn {
  width: 200px;
  font-size: 18px;
}
.tiny h1 {
  font-size: 26px;
  padding-bottom: 10px;
}
.tiny h2 {
  font-size: 18px;
}
.preview.tiny {
  height: 600px !important;
}
.preview.ultra_tiny {
  height: 450px !important;
}
.ultra_tiny h1 {
  font-size: 20px;
  padding-bottom: 10px;
}
.ultra_tiny h2 {
  font-size: 14.5px;
  padding-bottom: 25px;
}
.ultra_tiny .hero_btn {
  width: 200px;
  font-size: 15px;
  height: 40px;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid var(--m-white);
}
.ultra_tiny .title {
  top: 100px;
  left: 25px;
  color: var(--m-white);
  z-index: 15;
}
</style>