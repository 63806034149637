
<template>
    <div class="block" :class="
      clientWidth < 1200 && clientWidth > 1100
        ? 'medium'
        : '' || (clientWidth < 1100 && clientWidth > 900)
        ? 'small'
        : '' || (clientWidth < 900 && clientWidth > 720)
        ? 'tiny'
        : '' || (clientWidth < 720 && clientWidth >670) 
        ? 'ultra_tiny'
        : '' || clientWidth < 670
        ? 'mobile'
        : ''
    ">
        <div v-if="icons.stage_1" class="icon stage_1"></div>
        <div v-if="icons.stage_2" class="icon stage_2"></div>
        <div v-if="icons.stage_3" class="icon stage_3"></div>
        <div v-if="icons.stage_4" class="icon stage_4"></div>
        <ul class="collapsible" ref="collapsible">
        <li>
        <div class="collapsible-header">
            <h3>{{title}}</h3>
            <i class="material-icons">{{icon}}</i>
        </div>
        <div class="collapsible-body">
            <span><p>{{text}}</p></span>
        </div>
    </li></ul>
    </div>
    
</template>

<script>
import M from 'materialize-css' 

export default {
        data() {
            return {
            }
        },
        name: 'secadvantages',
        props:{
            src: String,
            title: String,
            text: String,
            icon: String,
            icons: Object,
            clientWidth: Number
        },
        mounted(){
            const sec = M.Collapsible.init(this.$refs.collapsible,{

            });
            if(this.clientWidth > 670)
            sec.open()
        },
        components:{
        },
        computed: {

        },
        watch: {
            // clientWidth(newValue){
            //     if(newValue<670){
            // this.sec.close()

            //     }
            // }
            
        },
        methods: {

        }
    
}
</script>
<style scoped>
    h3{
        text-align: center;
        font-weight: 900;
        font-size: 20px;
        text-transform: uppercase;
        color: var(--m-blue);
    }
    p{
        text-align: left;
        color: var(--m-blue);
        font-size: 16px;
        font-weight: 500;
    }
    .block{
        width: 285px;
        margin: 0 auto;
        backdrop-filter: blur(5px);
        /* background-image: url('../assets/img/icons/icon_1.png'); */
    }
    .icon{
        width: 160px;
        height: 160px;
        margin: 0 auto;
        background-size: contain;
        z-index: 9;
        /* backdrop-filter: blur(5px); */
        /* background: chocolate; */
    }
    .stage_1{
        background-image: var(--icon-1);
    }
    .stage_2{
        background-image: var(--icon-2);
    }
    .stage_3{
        background-image: var(--icon-3);
    }
    .stage_4{
        background-image: var(--icon-4);
    }

    .collapsible-header {
        display: inline-flex!important;
        line-height: unset!important;
        padding: 0!important;
        background-color: unset!important;
        border-bottom: none!important;
    }
    .collapsible-header i {
        color: var(--m-blue)!important;
        line-height: 80px!important;
        margin-left: 1rem!important;
        margin-right: none!important;

}
    .collapsible-body {
        padding: 0!important;
        border: none !important;

}
.collapsible {
    border: none!important;
    /* border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd; */
    margin: .5rem 0 1rem 0;
}
.collapsible {
    box-shadow: none!important;
}
.block.mobile{
    width: 135px;
    border-right: 2px solid transparent;
    padding: 5px;
}
/* .block.mobile:nth-child(3):before,
.block.mobile:nth-child(4):before{
    content: '';
    height: 2px;
    width: 135px;
    background-color: rgba(10,20,31, .5);
    position: absolute;
    top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.block.mobile:nth-child(1):before{
    content: '';
    height: 155px;
    width: 2px;
    background-color: rgba(10,20,31, .05);
    position: absolute;
    right: -15px;
    transform: translateX(7px);
    /* box-shadow: var(--black); */
    /* top: 5px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.block.mobile:nth-child(4):before{
    content: '';
    height: 155px;
    width: 2px;
    background-color: rgba(10,20,31, .05);
    position: absolute;
    left: -15px;
    transform: translateX(6px);
    /* box-shadow: var(--black); */
    /* top: -5px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.block.mobile:nth-child(2):before{
    content: '';
    height: 2px;
    width: 150px;
    left: 0;
    background-color: rgba(10,20,31, .05);
    position: absolute;
    bottom: 20px;
    transform: translateX(7px);
    /* box-shadow: var(--black); */
    /* top: 5px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.block.mobile:nth-child(3):before{
    content: '';
    height: 2px;
    width: 150px;
    background-color: rgba(10,20,31, .05);
    position: absolute;
    top: -20px;
    right: 0;
    transform: translateX(6px);
    /* box-shadow: var(--black); */
    /* top: -5px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.mobile .icon{
width: 100px;
height: 100px
}
.mobile h3{
    font-size: 13px;
    height: 28px;
}
.mobile p{
    font-size: 12px;
    line-height: 12px;
    width: 150px;
    padding-bottom: 15px;
}
</style>

