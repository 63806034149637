
<template>
  <section class="body">
  <a name="prices"></a>

  <h2>{{title}}</h2>
  <div class="cont">
    <div class="col_l">
      <div class="preview_img"></div>
      </div>
      <div class="col_r">
        <h3>Аренда спецтехники с экипажем<br>от 4 500 руб в час.*</h3>
        <p>
            Для оценки предварительной стоимости работ на Вашем водоёме, закажите бесплатный выезд специалиста или воспользуетесь нашим калькулятором.
        </p>
        <h4>*Минимальный заказ 10 смен по 10 часов. Тариф все включено:<br>
             все услуги, трансфер и проживание экипажа</h4>
             <btn class="master_btn" @sendForm="$emit('openModal')" :label="'оставить заявку'"/>
      </div>
  </div>
  </section>
</template>

<script>
import btn from '../btn.vue'
export default {
        data() {
            return {
            }
        },
        name: 'secprices',
        props:{
            title: String

        },
        components:{
            btn,
        },
        computed: {

        },
        watch: {

        },
        methods: {

        }
    
}
</script>
<style scoped>
    h2{
        color: var(--m-blue);
        font-size: 35px;
        font-weight: 900;
        text-transform: uppercase;
        padding: 50px 0;

    }
    h3{
        text-align: left;
        font-weight: 900;
        font-size: 25px;
        color: var(--m-blue);
    }
    h4{
        text-align: left;
        font-weight: 500;
        font-size: 15px;
        opacity: .7;
        color: var(--m-blue);
    }
    p{
        width: 600px;
        text-align: left;
        color: var(--m-blue);
        font-size: 17px;
        font-weight: 500;
        padding-right: 50px;
    }
    .cont{
        display: flex;
        margin: 0 100px;
    }
    .preview_img{
        width: 700px;
        height: 500px;
        margin-bottom: 50px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('../../assets/svg/prices_asset.svg');
    }
    .col_r{
        padding-top: 50px;
        padding-left: 50px;
    }
    .master_btn{
        margin-top: 50px;
        border: 2px solid var(--m-blue);
        color: var(--m-blue)!important;
        cursor: pointer;
        transition: all .525s ease-in-out;
    }
    .master_btn:hover{
       box-shadow: 
          -5px 0 10px 0 rgba(10,20,31, .2),
          5px 0 10px 0 rgba(10,20,31, .2),
          0 5px 10px 0 rgba(10,20,31, .2),
          0 -5px 10px 0 rgba(10,20,31, .2);
      color: var(--m-blue);
      border: 2px solid var(--m-blue);
    }
</style>