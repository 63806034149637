
<template>
  <section
    class="body"
    id="services"
    :class="
      clientWidth < 1200 && clientWidth > 1100
        ? 'medium'
        : '' || (clientWidth < 1100 && clientWidth > 900)
        ? 'small'
        : '' || (clientWidth < 900 && clientWidth > 720)
        ? 'tiny'
        : '' || (clientWidth < 720 && clientWidth > 670)
        ? 'ultra_tiny'
        : '' || clientWidth < 670
        ? 'mobile'
        : ''
    "
  >
  <a name="services"></a>
    <h2>{{ title }}</h2>
    <div class="cont">
      <div class="col_l">
        <p>Гидромеханическая очистка дна водоёма от ила и донных отложений</p>
        <p>Очистка водоёма от водорослей, камыша, травы, мусора и ТБО</p>
        <p>Расчистка ливнёвых канав и очистных сооружений</p>
        <p>Очистка и оборудование пожарных водоёмов</p>
        <p>Защита от наводнений и противопаводковые работы</p>
        <p>Дноуглубительные<br />работы</p>
        <p>Реабилитация заболоченных участков</p>
        <p>Водная мелиорация</p>
      </div>
      <div class="col_r">
        <p>Создание искусственных островов и водоёмов</p>
        <p>Благоустройство береговой линии и береговых сооружений</p>
        <p>Строительство дамб, причалов и плотин</p>
        <p>Установка свай в воде, болоте, торфе, глине</p>
        <p>Укрепление береговой линии, береговой ландшафт</p>
        <p>Добыча сапропеля и других донных отложений</p>
        <p>Подготовка дна для прокладки инженерных сетей</p>
        <p>Намывка пляжа</p>
      </div>
    </div>
    <div class="footer">
      <p class="text">
        Более подробную информацию по стоимости и срокам работ Вы сможете узнать
        по телефону: <a href="tel:+7(985) 930-15-91">+7(985) 930-15-91</a> или
        оставив заявку
      </p>
      <btn :label="'оставить заявку'" @sendForm="$emit('openModal')" />
    </div>
  </section>
</template>

<script>
import btn from "../btn.vue";
export default {
  data() {
    return {};
  },
  name: "secservices",
  props: {
    title: String,
    clientWidth: Number,
  },
  components: {
    btn,
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style scoped>
.body {
  background-image: var(--services);
  background-size: cover;
  background-position: center;
}
.master_btn {
  margin-top: 25px;
  border: 2px solid var(--m-yellow);
  color: var(--m-yellow) !important;
  cursor: pointer;
  transition: all 0.525s ease-in-out;
}
.master_btn:hover {
  box-shadow: -5px 0 10px 0 rgba(249, 204, 51, 0.2),
    5px 0 10px 0 rgba(249, 204, 51, 0.2), 0 5px 10px 0 rgba(249, 204, 51, 0.2),
    0 -5px 10px 0 rgba(249, 204, 51, 0.2);
  color: var(--m-yellow);
  border: 2px solid var(--m-yellow);
}

h2 {
  margin: 0;
  padding: 50px 0;
  color: var(--m-white);
  font-size: 35px;
  font-weight: 900;
  text-transform: uppercase;
}
p {
  width: 350px;
  text-align: left;
  color: var(--m-white) !important;
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 20px;
  /* padding-right: 25px; */
}
.col_l p:last-child,
.col_r p:last-child {
  margin-bottom: 0;
}
.col_l p::before,
.col_r p::before {
  content: "";
  position: absolute;
  padding-right: 10px;
  border: 2px solid var(--m-yellow);
  border-radius: 50%;
  /* background: blueviolet; */
  transform: translate(-25px, 7px);
  height: 13.87px;
  opacity: 0.7;
}

.text {
  width: 700px;
  font-weight: 500;
}
.footer,
.cont {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 100px;
  backdrop-filter: blur(1px);
}
.footer {
  margin: 50px auto;
}
.col_l,
.col_2 {
  min-width: 450px;
  margin: 0 auto;
}
.medium .cont,
.small .cont,
.tiny .cont ,
.ultra_tiny .cont{
  padding: 0 50px;
}
.mobile .cont {
  padding: 0 25px;
}
</style>