<template>
  <div class="project">
    <div v-if="!edited" class="date">{{ project.date }}</div>

    <div class="body">
      <img v-if="!editImg" :src="project.image" class="img" @click="imgClick" />

      <div v-if="editImg" class="file-field input-field col s6">
        <div class="btn">
          <span><i class="material-icons">attach_file</i></span>

          <input
            type="file"
            ref="fileInput"
            accept="image/x-png,image/gif,image/jpeg"
            @change="getFile"
          />
        </div>

        <div v-if="edited" class="file-path-wrapper">
          <input
            class="file-path validate"
            type="text"
            placeholder="Загрузить файл"
          />
        </div>
      </div>
      <div class="close" v-if="edited" @click="close">
        <p>внесите изменения</p>
        <i class="material-icons">close</i>
      </div>
      <div v-if="!edited" @click="imgClick" class="title">
        {{ project.title }}
      </div>
      <div v-else class="title">
        <input class="input-field edit_input" v-model="newTitle" />
      </div>

      <div v-if="!edited" class="subtitle">{{ project.subTitle }}</div>

      <div v-else class="subtitle">
        <input class="input-field edit_input" v-model="newSubTitle" />
      </div>

      <div v-if="edited" class="subtitle">
        <input class="input-field edit_input" v-model="newDate" />
      </div>

      <btn
        v-if="edited"
        class="btn_master"
        :label="'сохранить'"
        @sendForm="edit"
      />
    </div>
  </div>
</template>

<script>
import btn from "./btn";
export default {
  data() {
    return {
      editImg: false,
      edited: false,
      newSubTitle: "",
      newTitle: "",
      newDate: "",
      newFile: "",
    };
  },
  name: "projectComp",
  props: {
    project: Object,
    i: Number,
    reload: Function,
    admin: Boolean,
  },
  components: {
    btn,
  },
  created() {},
  computed: {},
  watch: {
    edited(newValue) {
      if (newValue) {
        this.newTitle = this.project.title;
        this.newSubTitle = this.project.subTitle;
        this.newDate = this.project.date;
      }
    },
  },
  methods: {
    imgClick() {
      if (this.admin) {
        this.editImg = true;
        this.edited = true;
      }
    },
    async edit() {
      if (this.admin) {
        const formData = new FormData();
        formData.append("file", this.newFile);
        formData.append("title", this.newTitle);
        formData.append("subTitle", this.newSubTitle);
        formData.append("date", this.newDate);
        formData.append("id", this.project._id);
        await this.$axios.post("project/edit", formData);
        this.reload();
        this.edited = !this.edited;
      }
    },

    getFile() {
      this.newFile = this.$refs.fileInput.files[0];
    },
    close() {
      this.editImg = false;
      this.edited = false;
    },
  },
};
</script>
<style scoped>
.project {
  color: var(--m-white);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  min-width: 370px;
  /* width: 350px; */
}
.body {
  margin-left: 10px;
}
.date {
  font-size: 30px;
  padding-left: 50px;
  transform: rotate(-90deg);
  transform-origin: left;
  height: 90px;
  opacity: 0.4;
  text-align: end;
}
.img {
  width: 300px;
  border: 2px solid var(--m-white);
  height: 250px;
  pointer-events: none;
  float: left;
  margin-bottom: 20px;
}
.title {
  text-align: left;
  font-size: 20px;
  font-weight: 900;
  text-transform: lowercase;
}
.subtitle {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.7;
}
.title::first-letter {
  text-transform: uppercase;
}
.input-field {
  width: 300px !important;
  font-family: "Jura" !important;
}
.close {
  margin: 25px 0;
  text-align: end;
  width: 300px;
  display: flex;
}
.close i {
  line-height: 30px;
  font-size: 20px;
  padding-left: 65px;
  cursor: pointer;
}
.btn_master {
  margin: 25px auto;
}
p {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  /* width: 200px; */
  margin: 0;
  padding: 0;
}
</style>