<template>
  <section class="body">
  <a name="zones"></a>
  <h2>{{title}}</h2>
  <div class="cont">
    <p class="subtitle">Наша компания выполняет работы по всему региону ЦФО. Трансфер техники от
г. Волоколамск  осуществляется за счёт Заказчика.  Техника подходит под
разрешенные габариты  и вес для авто и ж/д транспортировки.</p>
        <div class="zones_nav">
            <p class="zones_title">зоны трансфера</p>
            <p class="zone_name">1 км - индивидуально</p>
            <p class="zone_name">1 км - 100 руб.</p>
        </div>
    <div class="map"></div>

  </div>
  </section>
</template>

<script>
export default {
        data() {
            return {

            }
        },
        name: 'seczones',
        props:{
            title: String

        },
        created(){

        },
        components:{

        },
        computed: {

        },
        watch: {
            
        },
        methods: {

        }
    
}

</script>
<style scoped>
.body{
    /* background: var(--m-blue); */
    background-image: var(--zones);
    background-size: cover;
    background-position: top;
    background-origin: border-box;

    position: relative;

}
h2{
    margin: 0;
    padding: 50px 0;
    color: var(--m-white);
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;
    }
.cont{
    margin: 0 100px;
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    backdrop-filter: blur(1px);
}
.subtitle{
    z-index: 2;
    /* padding-bottom: 350px; */
    opacity: .7;
    font-weight: 500;
    font-size: 22px;
    color: var(--m-white);
    text-align: left;
    }
.zones_title{
    color: var(--m-white);
    font-size: 25px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    }
.zones_nav{
    z-index: 3;
    padding-top: 25px;
    padding-bottom: 150px;
    }
.zone_name{
    display: flex;
    color: var(--m-white);
    font-size: 20px;
    font-weight: 600;
    text-align: left;
}
.zone_name::before{
    display: inline-block;
    content: '';
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
.zone_name:nth-child(2)::before{
    background: rgba(9,26,41,1);
    border: 2px solid var(--m-white)
}
.zone_name:nth-child(3)::before{
    background: rgba(249,204,41, .5);
    border: 2px solid var(--m-white)
}        
.map{
    left: 0;
    margin-top: 100px;
    position: absolute;
    height: 750px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: var(--map-2);
}
</style>