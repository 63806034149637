
<template>
<div class="cont">
    <div class="wrp">
    <div class="col_c_1">

        <div class="waves_header">
            <!--WAVES-->
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g class="parallax">
                        <use xlink:href="#gentle-wave" x="48" y="0"></use>
                        <use xlink:href="#gentle-wave" x="48" y="2"></use>
                        <use xlink:href="#gentle-wave" x="48" y="5"></use>
                        <use xlink:href="#gentle-wave" x="48" y="3"></use>
                    </g>
                </svg>
            <!--WAVES END-->
        </div>

        <div class="boat"></div>
    </div>

    <div class="col_r">
        <p>Земснаряд относится к классу маломерных судов, способен справиться с любыми задачами по благоустройству территорий.
            Особенностью этой установки является грунтовый насос и уникальная фреза с измельчителем, которая способствует работе
            насоса без засоров.</p>
        <p>Все механизмы приводятся в действие с помощью гидравлической силы. На земснаряде установлен грунтовый абразивостойкий
            насос в трюме, двойной корпус и крыльчатка которого изготовлены из высококачественного износостойкого чугуна.</p>
        <p>Сочетание экскаваторной стрелы и грунтового насоса делает установку многофункциональной, способной справится
            с различными задачами на воде и на суше.</p> 
        <p>Корпус судна имеет специальную обработку и покраску, которые соответствуют морским требованиям. Все изнашиваемые узлы изготовлены и высококачественных материалов.</p>    
    </div>
    </div>
    <div class="ill"></div>
</div>
  
</template>

<script>


export default {
        data() {
            return {

                    }
        },
        name: 'slide1',
        props:{

        },
        mounted(){

        },
        components:{

        },
        computed: {

        },
        watch: {           
        },
        methods: {
          

        }
    
}
</script>
<style scoped>
    .cont{
        display: inline-flex;
        margin: 0 100px;
    }
    
    .col_c_1{
        margin: 0 50px;
        margin-top: 25px;
        width: 600px;
        height: 600px;
        /* background: turquoise; */
    }
    .col_r{
        width: 500px;
        height: 500px;
        padding-top: 150px;
        padding-left: 30px;
        color: var(--m-white);
        text-align: left;
    }
    .col_r p{
        padding-left: 12px;
    }

        /* ILLUSTRATIONS */
    .wrp{
        position: absolute;
        display: inline-flex;
        left: 100px;

        width: 100vw;
        z-index: 5;
    }    
    .ill{
        width: 350px;
        height: 650px;
        background: var(--fabric);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        transform: translateY(100px);
        right: 0;
    }

     /* BOAT ANIMATION */
  .boat{
        z-index: 3;
        position: absolute;
        background-image: var(--zemsanaryad);
        background-size: contain;
        background-repeat: no-repeat;
        width: 600px;
        height: 600px;
    }
    .waves_header {
        display: block;
        opacity: 1;
        z-index: 1;
        padding-top: 90px;
        visibility: visible;
        position: absolute;
        width: 500px;
        margin-left: 20px;
        color: white;
    }
    .waves {
        /* position: relative; */
        width: 100%;
        height: 10vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 100px;
        max-height: 150px;
    }
    /* Animation */

    .parallax > use {
        transition: all .525s ease-in-out;
        will-change: transform;
        animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
        fill: rgba(23,68,92, .7);

        /* fill: rgba(178, 235, 242, .7); */
    }

    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
        animation:
            move-forever 10s cubic-bezier(.55, .5, .45, .5) infinite,
            gradient 15s ease-in-out infinite alternate;
        fill: rgba(178, 235, 242, .5);
    }

    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation:
            move-forever 13s cubic-bezier(.55, .5, .45, .5) infinite,
            gradient 15s ease-in-out infinite alternate;
        fill: rgba(178, 235, 242, .3);
        
    }

    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
        /* fill: rgba(107, 194, 220, 1); */
        fill: rgba(23,68,92, .7)

    }
    @keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

</style>