<template>
  <div class="about">
    <div class="logo"></div>
    <h1>
      В данный момент на сайте ведутся технические работы, приносим свои
      извинения за неудобства. Вы сможете связаться с нами любым удобном
      для вас способом ниже
    </h1>
    <div class="icons_wrp">
      <a class="row" href="tel:+79859301591">
        <i class="material-icons">phonelink</i>
        <p>+7(985)930-15-91</p></a
      >
      <a class="row" href="https://t.me/akvabereg">
        <i class="material-icons">send</i>
        <p>telegram</p>
      </a>
      <a class="row" href="https://wa.me/79859301591">
        <i class="material-icons">message</i>
        <p>whatsapp</p>
      </a>
      <a
        class="row"
        href="https://www.youtube.com/channel/UCENKK2y7mXoyDZUZOIVDw5g"
      >
        <i class="material-icons">video_library</i>
        <p>видео работ</p>
      </a>
      <a class="row" href="mailto:info@akvabereg.ru">
        <i class="material-icons">mail</i>
        <p>info@akvabereg.ru</p>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.about {
  height: 100vh;
  min-width: 920px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
h1 {
  font-size: 24px;
}
.logo {
  background-image: url("../assets/svg/logo_brown.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 350px;
  height: 70px;
}
.icons_wrp {
  /* width: 550px; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  font-size: 16px;
}
p {
  padding: 0 0px 5px 5px;
}
.row {
  display: flex;
  flex-direction: row;
  opacity: 0.75;
  margin: 0 15px 0 0;
  border-right: 1px solid rgba(10,20,31, .25);
  padding: 0 10px 0 0;
  transition: opacity 0.525s ease-in-out, border-bottom 0.525s ease-in-out, border-right 0.525s ease-in-out;
  border-bottom: 2px solid transparent;
}

.row:last-child {
  border-right: 1px solid rgba(10,20,31, 0);

}
.row:hover {
  opacity: 1;
  border-bottom: 2px solid var(--m-yellow);
  border-right: 1px solid rgba(10,20,31, 0);

}
</style>