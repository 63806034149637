<template>
    <div class="project">
        <div class="date">{{date}}</div>
        <div class="body">
            <img :src="src" class="img"/>
            <div class="title">{{title}}</div>
            <div class="subtitle">{{subtitle}}</div>    
        </div>
    </div>
</template>

<script>

export default {
        data() {
            return {
            }
        },
        name: 'project',
        props:{
            src: String,
            date: String,
            title: String,
            subtitle: String

        },
        created(){
            // this.getImg()
        },
        components:{

        },
        computed: {

        },
        watch: {
            
        },
        methods: {

        }
    
}

</script>
<style scoped>
    .project{
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
        width: 350px;

    }
    .body{
        margin-left: 10px;
    }
    .date {
        font-size: 30px;
        transform: rotate(-90deg);
        transform-origin: left;
        height: 90px;
        opacity: .4;
        text-align: end;
        /* color: var(--m-white); */
    }
    .img{
        width: 300px;
        border: 2px solid var(--m-white);
        height: 250px;
        pointer-events: none;
        float: left;
    }
    .title{
        text-align: left;
        margin-top: 25px;
        font-size: 20px;
        font-weight: 900;
        /* color: var(--m-white); */
        text-transform: lowercase;

    }
    .subtitle{
        word-wrap: break-word; 
        text-align: left;
        font-size: 15px;
        font-weight: 500;
        /* color: var(--m-white); */
        opacity: .7;

    }
    .title::first-letter{
        text-transform: uppercase;
    }

</style>