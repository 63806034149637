import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueMask from 'v-mask'
import Vuelidate from 'vuelidate'
import M from 'materialize-css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import '../node_modules/materialize-css/dist/css/materialize.min.css'
import 'locomotive-scroll/dist/locomotive-scroll.min.css'
import 'swiper/swiper-bundle.css'
import VueCookies from 'vue-cookies'


Vue.use(VueCookies)

Vue.config.devtools = true

M.AutoInit()
Vue.use(Vuelidate)

Vue.use(VueMask, {
  placeholders: {
    D: /\d/,
    Я: /[\wа-яА-Я]/
  }
})

Vue.use({
  install(Vue) {
      Vue.prototype.$axios = axios.create({
          withCredentials: true,
          baseURL: process.env.NODE_ENV == 'development' ? 'http://localhost:3000/api/' : 'https://www.akvabereg.ru/api/'
      })
  }
})
if(process.env.NODE_ENV == 'development'){
  Vue.config.devtools = true;
}

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
