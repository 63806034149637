
<template>
  <div class="body">
  <h2>{{title}}</h2>
  <div class="cont">
      <div class="col_l">
          <stage :src="'../../assets/img/icons/stage_1.png'" :number="'01'" :description="'Бесплатный выезд специалиста на объект'"/>
          <stage :src="'./assets/img/icons/stage_1.png'" :number="'02'" :description="'Составление ТЗ и сметной документации'"/>
          <stage :src="'../../assets/img/icons/stage_1.svg'" :number="'03'" :description="'Подписание договора и технической документации'"/>
      </div>
      <div class="col_r">
          <stage :src="'../../assets/img/icons/stage_1.svg'" :number="'04'" :description="'Выезд бригады и транспортировка техники'"/>
          <stage :src="'../../assets/img/icons/stage_1.svg'" :number="'05'" :description="'Выполнение работ'"/>
          <stage :src="'../../assets/img/icons/stage_1.svg'" :number="'06'" :description="'Технический аудит и сдача объекта'"/>
      </div>
  </div>

  </div>
</template>

<script>
import stage from '../stage.vue'
export default {
        data() {
            return {
            }
        },
        name: 'secstages',
        props:{
            title: String

        },
        components:{
            stage

        },
        computed: {

        },
        watch: {
            
        },
        methods: {

        }
    
}
</script>
<style scoped>
    .body{
        background-image: var(--stages);
        background-size: cover;
    }
    h2{
        color: var(--m-white);
        margin: 0;
        padding: 50px 0;
        font-size: 35px;
        font-weight: 900;
        text-transform: uppercase;

    }
    p{
        text-align: left;
        color: var(--m-blue);
        font-size: 18px;
        font-weight: 900;
        /* padding-right: 25px; */
    }
    .num{
        font-size: 70px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid;
    }
    .descripion{
        margin: 10px 0;
        width: 250px;
        line-height: 1!important;
    }
    .icon{
        width: 160px;
        height: 160px;
        background: violet;
    }
    .stage{
        margin-bottom: 50px;
    }
    .cont{
        display: inline-flex;
        margin: 0 100px;
    }
    .col_l,
    .col_2{
        /* width: 600px; */
        margin: 0 auto;
    }
</style>