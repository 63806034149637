<template>
  <div class="body">
  <a name="projects"></a>
  <h2>{{title}}</h2>
  <div class="cont">
    <p class="subtitle">Благодаря наличию всех необходимых ресурсов и компетенций, наши эксперты<br>
    готовы оперативно и качественно выполнить полный спектр интересующих Вас услуг</p>
    
    <projectcomp v-for="(project,i) in data"
            :admin="admin" 
            :key="project._id"
            :i="i"
            :project="project"
            :date="project.date"
            :title="project.title"
            :subtitle="project.subTitle"/>
  </div>
  </div>
</template>

<script>
import projectcomp from '../projectComp.vue'
export default {
        data() {
            return {
                data: [],
                admin: false
            }
        },
        name: 'secprojects',
        props:{
            title: String

        },
        created(){
            this.getAll()
        },
        components:{
            projectcomp

        },
        computed: {

        },
        watch: {
            
        },
        methods: {
            async getAll(){
                const data = await this.$axios.get('project/all')
                this.data = data.data
                }

        }
    
}

</script>
<style scoped>
.body{
    background-image: var(--projects);
    background-origin: border-box;
    background-size: cover;
    background-position: bottom;
    /* height: 550px; */
}
h2{
    margin: 0;
    padding: 50px 0;
    color: var(--m-white);
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;
    }
.cont{
    margin: 0 100px;
    display: inline-flex;
    flex-wrap: wrap;
}
.subtitle{
    margin: 0 auto;
    padding-bottom: 150px;
    opacity: .7;
    font-weight: 500;
    font-size: 22px;
    color: var(--m-white);
    }
</style>