<template>
  <div>
   <input :id="id"
          type="text"
          ref="datePicker"
          class="datepicker"
          v-model.lazy="inputData"/>
<label :for="id">{{label}}</label>

  </div>
</template>

<script>
import M from 'materialize-css' 

export default {
  name: 'formdatepicker',
   data() {
            return {
              inputData: '',
            }
        },
        mounted(){
          M.Datepicker.init(this.$refs.datePicker,{
            format: 'dddd, dd-ое mmm.',
            // format: 'dd mm',
            defaultDate: new Date(),
            setDefaultDate: false,
            autoClose: true,
            selectMonths: false,
            firstDay: 1,
            onSelect: (date) => {
                this.$emit('inputData', date)

              console.log(date)
            },
            minDate: new Date(),
            minYear: new Date(),
            maxYear: new Date(),
            i18n: {
              today: 'Сегодня',
              clear: 'Очистить',
              close: 'Закрыть',
              cancel: 'Закрыть',
              labelMonthNext: 'Next month',
              labelMonthPrev: 'Previous month',
              labelMonthSelect: 'Select a month',
              labelYearSelect: 'Select a year',
              months: ['Январь','Февраль','Март','Апрель','Май','Июнь', 
              'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
              monthsShort: ['Янв','Фев','Мар','Апр','Май','Июн', 
              'Июл','Авг','Сен','Окт','Ноя','Дек'],
              weekdays: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
              // weekdaysShort: ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
              weekdaysShort: ['Вск','Пнд','Втр','Срд','Чтв','Птн','Сбт'],
              weekdaysAbbrev: [ 'В', 'П', 'В', 'С', 'Ч', 'П', 'С' ] 
              },
              onClose: ()=> {
              if(this.modal){
                setTimeout(()=>{
                  const body = document.getElementsByTagName('body')[0]
                  body.style.overflow='hidden'
                },1000)}
              else{
                const body = document.getElementsByTagName('body')[0]
                body.style.overflow=''
              }  
          }}
          )
        },
        computed:{
          maxDate(){
            var toYear = this.date.getFullYear();
       var toMonth = this.date.getMonth();
       var toDay = this.date.getDate();
            return new Date(new Date(toYear, toMonth, toDay + 9))
          }
        },
        watch:{
        // inputData(newValue){
        //         if(this.inputData){
        //         this.$emit('inputData', newValue)
        //         }
        //   }
        },
        props:{
            label: String,
            id: String,
            modal: Boolean
        },
        methods:{}
}
</script>

<style scoped>

</style>