<template>
  <div>
    
    <textarea :id="id" 
              class="materialize-textarea"
              v-model="inputData"></textarea>
      <label :for="id">{{label}}</label>

  </div>
</template>

<script>
export default {
  name: 'formtextarea',
   data() {
            return {
              inputData: ''

            }
        },
        watch:{
        inputData(newValue){
                this.$emit('inputData', newValue)
          }
        },
        props:{
            label: String,
            id: String
        },
        computed:{},
        methods:{} 
}
</script>

<style scoped>

</style>