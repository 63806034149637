<template>
  <div>
      <input :id="id"
              :type="type"
              :class="{'valid':isCompleted}"
              v-model="inputData"
              v-mask="mask">
              
      <label :for="id">{{label}}</label>
      <!-- <span class="helper-text" data-error="некорректные данные"></span> -->
  </div>
</template>

<script>
export default {
  name: 'form_input',
   data() {
            return {
              inputData: '',
              isCompleted: false
            }
        },
        watch:{
        inputData(newValue){
                if(this.id =='phone' && newValue.length>16 || this.id =='name' && newValue.length>1){
                  this.isCompleted = true
                }
                else{
                   this.isCompleted = false
                }
                this.$emit('inputData', newValue, this.isCompleted)
          }
        },
        props:{
            label: String,
            id: String,
            type: String,
            mask: String,
        },
        computed:{},
        methods:{} 
}
</script>

<style scoped>


</style>