<template>
  <div class="body" v-if="admin">
    <mheader ref="header" :admin="admin" :username="user" />

    <div class="cont top_side">
      <div class="col_l add_label z-depth-3">
        <h4>создать новый проект</h4>

        <forminput
          label="Заголовок"
          :id="'title'"
          :type="'text'"
          :class="'input-field'"
          @inputData="getTitle"
        />

        <forminput
          label="Подзаголовок"
          :id="'subTitle'"
          :type="'text'"
          :class="'input-field'"
          @inputData="getSubTitle"
        />
        <div class="file-field input-field">
          <div class="btn">
            <span><i class="material-icons">attach_file</i></span>
            <input
              type="file"
              ref="fileInput"
              accept="image/x-png,image/gif,image/jpeg"
              @change="getFile"
            />
          </div>
          <div class="file-path-wrapper">
            <input
              class="file-path validate"
              type="text"
              placeholder="Загрузить файл"
            />
          </div>
        </div>


  <div class="input-field col s12">
    <select>
      <option value="" disabled selected>Choose your option</option>
      <option value="1">Option 1</option>
      <option value="2">Option 2</option>
      <option value="3">Option 3</option>
    </select>
    <label>Materialize Select</label>
  </div> 
        <!-- <div class="input-field">
          <select ref="selectDate" v-model="date">
            <option value="" disabled selected>Выберите дату</option>
            <option :value="`1-ый квартал ${thisYear} г.`">
              1-ый квартал {{ thisYear }} г.
            </option>
            <option :value="`2-ой квартал ${thisYear} г.`">
              2-ой квартал {{ thisYear }} г.
            </option>
            <option :value="`3-ий квартал ${thisYear} г.`">
              3-ий квартал {{ thisYear }} г.
            </option>
            <option :value="`4-ый квартал ${thisYear} г.`">
              4-ый квартал {{ thisYear }} г.
            </option>
          </select>
          <label>Materialize Select</label>
        </div> -->


      </div>
      <div class="col_r">
        <project :date="date" :title="title" :subtitle="subTitle" :src="src" />
        <btn class="btnSave" @sendForm="send" :label="'опубликовать'" />
        <!-- :class="{'disabled': !ready}"/> -->
      </div>
    </div>
    <!-- <secprojects data-scroll-section class="projects" :title="'последние проекты'"/> -->

    <div class="cont bottom_side">
      <h2>Наши проекты</h2>
      <projectComp
        v-for="(project, i) in data"
        :key="project._id"
        :i="i"
        :admin="admin"
        :project="project"
        :reload="getAll"
      />
    </div>
  </div>
</template>

<script>
import M from "materialize-css";
import forminput from "../components/form/forminput";
import btn from "../components/btn";
import projectComp from "../components/projectComp";
import project from "../components/project";
import mheader from "../components/header";

M.AutoInit();

export default {
  name: "NewProject",
  data() {
    return {
      admin: false,
      ready: false,
      username: "",
      src: "",
      loaded: false,
      data: [],
      file: "",
      title: "Заголовок",
      subTitle: "Подзаголовок",
      date: `1-ый квартал ${new Date().getFullYear()} г.`,
    };
  },
  components: {
    forminput,
    btn,
    projectComp,
    project,
    mheader,
  },
  computed: {
    thisYear() {
      return new Date().getFullYear();
    },
  },
  watch: {},
  mounted() {
    M.FormSelect.init(this.$refs.selectDate, {});
  },
  created() {
    this.testAuth();
    this.getAll();
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    getSubTitle(inputData) {
      this.subTitle = inputData;
    },
    getTitle(inputData) {
      this.title = inputData;
    },
    async getAll() {
      const data = await this.$axios.get("project/all");
      this.data = data.data;
      this.loaded = true;
    },
    async testAuth() {
      const isAuth = await this.$axios.post("user/testAuth");
      if (isAuth.data.auth) {
        console.log(isAuth.data.user.username);
        this.admin = isAuth.data.auth;
        this.user = isAuth.data.user.username;
      }
    },
    getFile() {
      this.file = this.$refs.fileInput.files[0];
      this.src = URL.createObjectURL(this.file);
    },
    async send() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("title", this.title);
      formData.append("subTitle", this.subTitle);
      formData.append("date", this.date);

      await this.$axios.post("project/new_project", formData);
      this.stc = null;
      this.getAll();
    },
  },
};
</script>
<style scoped>
h2 {
  display: block;
  width: 100%;
  /* margin: 50px 0; */
  color: var(--m-white);
}
h4 {
  text-align: left;
}
.add_label {
  background: #efefef;
}

.bottom_side {
  display: flex;
  flex-wrap: wrap;
  background: var(--m-blue) !important;
}
.master_btn {
  color: var(--m-blue);
  border: 2px solid var(--m-blue);
}
.btnSave {
  cursor: pointer;
  margin: 0 auto;
}
.cont {
  padding: 50px 50px;
  display: flex;
  align-items: end;
}
.col_l {
  padding: 40px;
  border-radius: 30px;
  margin: 0 auto;
  width: 500px;
  /* background: var(--m-white); */
}
.col_r {
  margin: 0 auto;
  /* padding: 50px; */
  width: 600px;
  padding: 40px;
  border-radius: 30px;
  background: transparent;
  backdrop-filter: blur(5px);
}
.disabled {
  pointer-events: none;
  opacity: 0;
}

.input-field > label {
  font-size: 18px !important;
  color: var(--m-black) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  --webkit-box-shadow: 0 0 0 30px transparent inset !important;
  background-color: transparent !important;
  background: transparent !important;
  transition: background-color 500000s ease-in-out 0s !important;
  -webkit-text-fill-color: var(--m-black) !important;
  --webkit-background-clip: text;
  border-bottom: 2px solid var(--m-yellow) !important;
  border-left: none !important;
  border-right: none !important;
}
input:not([type]),
input[type="text"]:not(.browser-default),
input[type="password"]:not(.browser-default),
input[type="email"]:not(.browser-default),
input[type="url"]:not(.browser-default),
input[type="time"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="datetime"]:not(.browser-default),
input[type="datetime-local"]:not(.browser-default),
input[type="tel"]:not(.browser-default),
input[type="number"]:not(.browser-default),
input[type="search"]:not(.browser-default),
textarea.materialize-textarea {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow 0.3s, border 0.3s;
}
input:-internal-autofill-selected {
    appearance: menulist-button;
    background-color: transparent !important;
    }
</style>