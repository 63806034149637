
<template>
          <div class="stage">
              <!-- <div class="icon"></div> -->
              <img class="icon" :src="src"/>
              <div class="stage_body">
                  <p class="num">{{number}}</p>
                  <p class="descripion">{{description}}</p>
              </div>
          </div>
</template>

<script>

export default {
        data() {
            return {
            }
        },
        name: 'stage',
        props:{
            src: String,
            number: String,
            description: String

        },
        components:{

        },
        computed: {

        },
        watch: {
            
        },
        methods: {

        }
    
}
</script>
<style scoped>
    p{
        text-align: left;
        color: var(--m-white);
        font-size: 18px;
        font-weight: 900;
        /* padding-right: 25px; */
    }
    .num{
        width: 200px;
        font-size: 70px;
        margin: 0;
        padding: 0;
        border-bottom: 2px solid;
        letter-spacing: -.10em;
        font-weight: 500;
    }
    .descripion{
        margin: 10px 0;
        width: 280px;
        line-height: 1!important;
    }
    .icon{
        width: 160px;
        height: 160px;
        /* background: violet; */
        margin-right: 10px;
    }
    .stage{
        width: 500px;
        display: inline-flex;
    }
</style>