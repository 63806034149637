
<template>
  
<div class="cont">
    <div class="wrp">
    <div class="col_c_1">

        <div class="waves_header">
            <!--WAVES-->
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g class="parallax">
                        <use xlink:href="#gentle-wave" x="48" y="0"></use>
                        <use xlink:href="#gentle-wave" x="48" y="2"></use>
                        <use xlink:href="#gentle-wave" x="48" y="5"></use>
                        <use xlink:href="#gentle-wave" x="48" y="3"></use>
                    </g>
                </svg>
            <!--WAVES END-->
        </div>
        
        <div class="boat"></div>
    </div>

    <div class="col_r">
        <p>- Очистка и дноуглубление водоемов, водосточных каналов и трубопроводов.</p>
        <p>- Очистка водоёмов от растений, водорослей, ила,  мусора, шлама, хвостов и т.д.</p>    
        <p>- Добыча гравия, песка, глины (грунт l-V, V категории ), торфа, сапропеля, водорослей.</p>     
        <p>- Участие в строительстве дамб, мостов, плотин, установка и укрепление сооружений на болотах.</p>     
        <p>- Копка траншей для кабеля, водопровода, канализации.</p>    
        <p>- Строительство сооружений в не проходимых местах.</p>
        <p>- Забивка свай, шпунтов, бревен, ограждений.</p>      
        <p>- Работы по локализации пожаров и спасению людей.</p>
        <p>- Укрепление береговой линии.</p> 
        <p>- Мелиорация.</p>    
    </div>
    </div>
    <div class="ill"></div>
</div>
  
</template>

<script>

export default {
        data() {
            return {

                    }
        },
        name: 'slide2',
        props:{

        },
        mounted(){

        },
        components:{

        },
        computed: {

        },
        watch: {           
        },
        methods: {

        }
    
}
</script>
<style scoped>
    .cont{
        display: inline-flex;
        margin: 0 100px;
    }

    .col_c_1{
        margin: 0 50px;
        margin-top: 25px;
        width: 600px;
        height: 600px;
    }
    .col_r{
        width: 500px;
        height: 500px;
        padding-top: 150px;
        padding-left: 30px;
        color: var(--m-white);
        text-align: left;
    }
    .col_r p{
        padding-left: 20px;
    }

        /* ILLUSTRATIONS */
    .wrp{
        position: absolute;
        display: inline-flex;
        left: 100px;

        width: 100vw;
        z-index: 5;
    }    
    .ill{
        width: 350px;
        height: 650px;
        background: var(--engineer);
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        transform: translateY(100px);
        right: 0;
    }

     /* BOAT ANIMATION */
  .boat{
        z-index: 3;
        position: absolute;
        background-image: var(--zemsanaryad);
        background-size: contain;
        background-repeat: no-repeat;
        width: 600px;
        height: 600px;
    }
    .waves_header {
        display: block;
        opacity: 1;
        z-index: 1;
        padding-top: 90px;
        visibility: visible;
        position: absolute;
        width: 500px;
        margin-left: 20px;
        color: white;
    }
    .waves {
        /* position: relative; */
        width: 100%;
        height: 10vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 100px;
        max-height: 150px;
    }
    /* Animation */

    .parallax > use {
        transition: all .525s ease-in-out;
        will-change: transform;
        animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
        fill: rgba(23,68,92, .7);

        /* fill: rgba(178, 235, 242, .7); */
    }

    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
        animation:
            move-forever 10s cubic-bezier(.55, .5, .45, .5) infinite,
            gradient 15s ease-in-out infinite alternate;
        fill: rgba(178, 235, 242, .5);
    }

    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation:
            move-forever 13s cubic-bezier(.55, .5, .45, .5) infinite,
            gradient 15s ease-in-out infinite alternate;
        fill: rgba(178, 235, 242, .3);
        
    }

    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
        /* fill: rgba(107, 194, 220, 1); */
        fill: rgba(23,68,92, .7)

    }
    @keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}
</style>