<template>
  <section class="body">
  <a name="contacts"></a>
  <h2>{{title}}</h2>
  <div class="cont"> 
      <div class="left_info">
      <p class="title"><a href="tel:+79859301591"><i class="material-icons">phone_iphone</i>+7 (985) 930-15-91</a></p>
      <p class="title"><a href="mailto:info@akvabereg.info"><i class="material-icons">mail</i>info@akvabereg.info</a></p>
      <p class="title"><a href="https://yandex.ru/maps/-/CCU4uJgLgA"><i class="material-icons">map</i>Г.О. Волоколамск, ул. Овражная д. 2.</a></p>
      <p class="text">На все вопросы мы с радостью ответим по телефону или в мессенджерах</p>
      <div class="social_media">
           <p class="title_2"><a href="https://wa.me/79859301591"><i class="whatsapp"></i>WhatsApp</a></p>
           <p class="title_2"><a href="https://t.me/akvabereg"><i class="telegram"></i>Telegram</a></p>
           <p class="title_2"><a href="https://viber.click/79859301591"><i class="viber"></i>Viber</a></p>
      </div>
    <btn class="master_btn" @sendForm="$emit('openModal')" :label="'заказать обратный звонок'"/>

      </div>
    <div class="map"></div>

  </div>

  </section>
</template>

<script>
import btn from '../btn.vue'

export default {
        data() {
            return {
            }
        },
        name: 'seccontacts',
        props:{
            title: String

        },
        created(){

        },
        components:{
            btn
        },
        computed: {

        },
        watch: {
    
        },
        methods: {

        }
    
    
}

</script>
<style scoped>
.map{
    position: absolute;
    right: 50px;
    background-image: var(--contact-map);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-50px);
    width: 770px;
    height: 400px;
}
.body{
    /* background: var(--m-blue); */
    /* background-image: var(--zones); */
    background-size: cover;
    background-position: top;
    background-origin: border-box;
}
.title{
    transform: translateX(0) scale(1);
    font-size: 18px;
    cursor: pointer;
    transition: all .525s ease-in-out;
    margin-top: 10px;
    margin-bottom: 10px;
}
.title:hover{
    transform: translateX(-10px) scale(1.05);
}
.title_2{
    margin-top: 10px;
    padding-bottom: 5px;
    transform: scale(1);
    font-size: 14px;
    line-height: 14px;
    padding-left: 25px;
    margin-right: 15px;
    cursor: pointer;
    transition: all .525s ease-in-out;
}
.title_2:first-child{
    padding-left: 0;
}
.title_2:nth-child(2n),
.title_2:nth-child(3n){
    border-left: 1px solid var(--m-blue);
}
.title_2:hover{
    transform: scale(1.05);
}
.text{
    font-size: 18px;
    width: 350px;
    margin-bottom: 0px;
    margin-top: 25px;
}
.social_media{
    display: flex;
}
.material-icons{
    line-height: unset!important;
    color: var(--m-blue)!important;
    margin-right: 15px;
}
.left_info{
    display: block;
    text-align: left;
    margin-left: 150px;
}
h2{
    margin-top: 350px;
    padding: 50px 0;
    color: var(--m-blue);
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;
    }
.cont{
    margin: 0 100px;
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;
    color: var(--m-blue);
    }
.telegram,
.viber,
.whatsapp{
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    transform: translateY(5px);
    }
.whatsapp{
    background-image: var(--whatsapp);
    background-size: contain;
    background-repeat: no-repeat;
    }
.telegram{
    background-image: var(--telegram);
    background-size: contain;
    background-repeat: no-repeat;
    }
.viber{
    background-image: var(--viber);
    background-size: contain;
    background-repeat: no-repeat;
    }
    .master_btn{
        margin: 20px auto;
        width: 350px;
        text-align: center;
        border: 2px solid var(--m-blue);
        background-color: var(--m-blue)!important;
        color: var(--m-white)!important;
        cursor: pointer;
        transition: all .525s ease-in-out;
    }
    .master_btn:hover{
       box-shadow: 
          -5px 0 10px 0 rgba(10,20,31, .2),
          5px 0 10px 0 rgba(10,20,31, .2),
          0 5px 10px 0 rgba(10,20,31, .2),
          0 -5px 10px 0 rgba(10,20,31, .2);
      color: var(--m-blue);
      border: 2px solid var(--m-white);
    }
</style>