
<template>
  <section class="body">
  <a name="tech"></a>
  <h2>{{title}}</h2>

    <div class="nav_tab">
        <span class="li" @click="click(id='description')" :class="{'active':nav.description}">описание</span>
        <span class="li" @click="click(id='purpose')" :class="{'active':nav.purpose}">назначение</span>
        <span class="li" @click="click(id='specific')" :class="{'active':nav.specific}">характеристики</span>
        <span class="li" @click="click(id='video')" :class="{'active':nav.video}">видео</span>
    </div>

    <!-- <div class="nav_title_2" v-if="nav.description || nav.purpose">
        <p v-if="nav.description" class="title_2">#описание земснаряда</p>
        <p v-if="nav.purpose" class="title_2">#назначение земснаряда</p>
    </div> -->
    <div class="nav_title" v-if="nav.description || nav.purpose">
        <p class="title">Многофункциональный самоходный земснаряд с грунтовым насосом</p>
        <p class="sub_title">«botsman РА»</p>
    </div>
    <div class="nav_title_2" v-if="nav.specific || nav.video">
        <p v-if="nav.specific" class="title_2">#технические характеристики</p>
        <!-- <p v-if="nav.video" class="title_2">#видео работ</p> -->
        <p v-if="nav.specific" class="sub_title_2">BOTSMAN РА»<br>2020 г.в</p>
    </div>

    <slide1 v-if="nav.description"/>
    <slide2 v-if="nav.purpose" />
    <slide3 v-if="nav.specific"/>
    <slide4 v-if="nav.video"/>

</section>
  
</template>

<script>
import slide1 from '../slides/slide1.vue'
import slide2 from '../slides/slide2.vue'
import slide3 from '../slides/slide3.vue'
import slide4 from '../slides/slide4.vue'

export default {
        data() {
            return {
                nav: {
                    description: true,
                    purpose: false,
                    specific: false,
                    video: false
                    }
                }
        },
        name: 'sectech',
        props:{
            title: String

        },
        mounted(){

        },
        components:{
            slide1,
            slide2,
            slide3,
            slide4

        },
        computed: {

        },
        watch: {           
        },
        methods: {
            click(id){
                
                for(let i in this.nav){
                    this.nav[i] = false
                }
                this.nav[id] = true
            }

        }
    
}
</script>
<style scoped>
    .body{
        background: var(--m-blue);
        background-size: cover;
        height: 900px;
    }
    h2{
        margin: 0;
        padding: 50px 0;
        color: var(--m-white);
        font-size: 35px;
        font-weight: 900;
        text-transform: uppercase;
    }
    .text{
        width: 700px;
        font-weight: 500;
    }

    .cont{
        display: inline-flex;
        margin: 0 100px;
    }
    /* DESCRIPTION #1 */
    .nav_tab{
        float: right;
        display: inline-flex;
        margin: 50px 80px 50px 100%;
        color: var(--m-white)!important;
    }
    .nav_title{
        /* float: right; */
        color: var(--m-white);
        position: absolute;
        right: 0;
    }
    .title{
        width: 500px;
        color: var(--m-white);
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 900;
        margin: 0 100px;
        text-align: left;
    }
    .sub_title{
        width: 500px;
        color: var(--m-white);
        font-size: 55px;
        text-transform: uppercase;
        font-weight: 100;
        margin: 0 100px;
        text-align: left;
        opacity: .3;
    }

        .nav_tab{
        float: right;
        display: inline-flex;
        margin: 50px 80px 50px 100%;
        color: var(--m-white)!important;
    }

    /* DESCRIPTION #2 */
    .nav_title_2{
        float: left;
        padding-left: 20px;
        transform: translateY(-100px);
        color: var(--m-white);
        position: absolute;
        /* right: 0; */
    }
    .title_2{
        /* width: 500px; */
        color: rgba(112,112,112, .15);
        font-size: 40px;
        text-transform: uppercase;
        font-weight: 900;
        margin: 0 100px;
        text-align: left;
    }
    .sub_title_2{
        width: 500px;
        color: var(--m-white);
        font-size: 55px;
        /* text-transform: uppercase; */
        font-weight: 100;
        margin: 0 100px;
        line-height: 55px;
        text-align: left;
        opacity: .3;
    }
    .li{
        cursor: pointer;
        text-align: left;
        font-size: 15px;
        font-weight: 900;
        margin: 0 25px;
        opacity: .7
    }
    .li.active{
        border-bottom: 2px solid var(--m-white);
        opacity: 1;
        }
</style>