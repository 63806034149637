<template >
  <div data-scroll-container ref="scrollContainer" class="template">
    <!-- MODALS -->
    <modal :class="modal ? 'is_open' : 'is_hidden'" id="modal">
      <callbackform @closeForm="modal = false" @openThanks="setPerson"
    /></modal>
    <modal :class="thanks ? 'is_open' : 'is_hidden'" id="modal">
      <thanks :person="person" @closeThanks="thanks = false" />
    </modal>

    <mheader
      @nav="scrollTo"
      ref="header"
      :nav="nav"
      :admin="admin"
      :username="user"
      :clientWidth="clientWidth"
    />
    <hero
      :scrollY="scrollY"
      :class="nav.hero ? 'is_in_view' : ''"
      class="hero"
      @openModal="modal = true"
      :clientWidth="clientWidth"
    />
    <secabout
      :class="nav.about ? 'is_in_view' : ''"
      class="about"
      :title="'о нас'"
      :clientWidth="clientWidth"
    />
    <secadvantages class="advantages" :title="'наши преимущества'" :clientWidth="clientWidth"/>
    <secservices
      :class="nav.services ? 'is_in_view' : ''"
      class="services"
      :title="'услуги'"
      @openModal="modal = true"
      :clientWidth="clientWidth"
    />
    <secprices
      :class="nav.prices ? 'is_in_view' : ''"
      class="prices"
      :title="'стоимость работ'"
      @openModal="modal = true"
    />
    <secstages class="stages" :title="'этапы работ'" />
    <sectech
      :class="nav.tech ? 'is_in_view' : ''"
      class="tech"
      :title="'техника'"
    />
    <seccallback :modal="modal" class="callback" :title="'возникли вопросы?'" />
    <secprojects class="projects" :title="'последние проекты'" />
    <seczones
      :class="nav.zones ? 'is_in_view' : ''"
      class="zones"
      :title="'география работ'"
    />
    <seccontacts
      :class="nav.contacts ? 'is_in_view' : ''"
      class="contacts"
      :title="'контакты'"
      @openModal="modal = true"
    />
  </div>
</template>

<script>
import mheader from "../components/header";
import hero from "../components/sections/hero.vue";
import secabout from "../components/sections/secabout.vue";
import secadvantages from "../components/sections/secadvantages.vue";
import secservices from "../components/sections/secservices.vue";
import secprices from "../components/sections/secprices.vue";
import secstages from "../components/sections/secstages.vue";
import sectech from "../components/sections/sectech.vue";
import seccallback from "../components/sections/seccallback.vue";
import secprojects from "../components/sections/secprojects.vue";
import seczones from "../components/sections/seczones.vue";
import seccontacts from "../components/sections/seccontacts.vue";
import modal from "../components/modal.vue";
import thanks from "../components/thanks.vue";
import callbackform from "../components/callbackform.vue";

export default {
  name: "Home",
  data() {
    return {
      url: "",
      user: "",
      admin: false,
      modal: false,
      thanks: false,
      height: "",
      scrollY: 0,
      clientWidth: 0,
      secArr: [],
      person: {},
      nav: {
        hero: true,
        about: false,
        advantages: false,
        services: false,
        prices: false,
        stages: false,
        tech: false,
        callBack: false,
        projects: false,
        zones: false,
        contacts: false,
      },
    };
  },
  mounted() {
    this.scroll();
    let body = document.getElementsByTagName("body")[0];
    this.height = body.offsetHeight;
    this.testAuth();
  },
  components: {
    hero,
    secabout,
    secadvantages,
    secservices,
    secprices,
    secstages,
    sectech,
    seccallback,
    secprojects,
    mheader,
    seczones,
    seccontacts,
    modal,
    callbackform,
    thanks,
  },
  created() {
    this.cookies();
    this.clientWidth = document.documentElement.clientWidth;
    this.clientHeight = document.documentElement.clientHeight;
    window.addEventListener("resize", this.updateWidth);
  },
  watch: {
    thanks(newValue) {
      const body = document.getElementsByTagName("body")[0];
      if (newValue) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "";
      }
    },
  },
  computed: {},
  methods: {
    async cookies() {
      if (this.$cookies.get("guest_id")) {
        await this.$axios.post("lead/new_visit", {
          id: this.$cookies.get("guest_id"),
        });
      } else {
        const res = await this.$axios.post("lead/new_guest");
        this.$cookies.set("guest_id", res.data._id);
      }
    },
    scrollTo(item) {
      console.log(item);
      const secArr = document.getElementsByTagName("section");
      secArr.forEach((i) => {
        if (i.classList[1] == item) {
          let target = i.offsetTop - 40;
          window.scrollTo(0, target);
        }
      });
    },
    scroll() {
      window.addEventListener("scroll", () => {
        this.scrollY = window.scrollY;
        const secArr = document.getElementsByTagName("section");
        secArr.forEach((i) => {
          let scrollHeight = i.scrollHeight;
          let offsetTop = i.offsetTop - 50;
          let offsetBottom = scrollHeight + offsetTop;
          let className = i.classList[1];
          if (this.scrollY > offsetTop && this.scrollY < offsetBottom) {
            for (let j in this.nav) {
              if (j == className) {
                this.nav[j] = true;
              } else {
                this.nav[j] = false;
              }
            }
          }
        });
        if (this.scrollY > this.height - window.innerHeight - 50) {
          for (let j in this.nav) {
            this.nav[j] = false;
          }
          this.nav.contacts = true;
        }
      });
    },
    setPerson(data) {
      this.person = data;
      this.thanks = true;
    },
    async testAuth() {
      const isAuth = await this.$axios.post("user/testAuth");
      if (isAuth.data.auth) {
        console.log(isAuth.data.user.username);
        this.admin = isAuth.data.auth;
        this.user = isAuth.data.user.username;
      }
    },
    updateWidth() {
      this.clientWidth = window.innerWidth;
    },
  },
};
</script>
<style scoped>
#modal {
  transition: all 0.525s ease-in-out;
}
.is_hidden {
  filter: blur(10px);
  transform: scale(0);
  display: none;
  opacity: 0;
  visibility: hidden;
}
.is_open {
  filter: blur(0px);
  transform: scale(1);
  display: flex;
  opacity: 1;
  visibility: visible;
}
</style>