<template>
      <!-- input -->
    <div class="input">
    <label>
           <input type="checkbox"
                id="indeterminate-checkbox"
                v-model="inputData"/>
                <span></span>
        </label>
    </div>
</template>
<script>
export default {
  name: 'leadcheckbox',
   data() {
            return {
              inputData: false
            }
        },
        watch:{
        checkBox(newValue){
          this.inputData = newValue
        },  
        inputData(newValue){
                this.$emit('inputData', newValue)
          }
        },
        props:{
          checkBox: Boolean
        },
        computed:{},
        methods:{} 
}
</script>

<style scoped>
[type="checkbox"]+span:not(.lever):before, 
[type="checkbox"]:not(.filled-in)+span:not(.lever):after {
    width: 14px!important;
    height: 14px!important;
    top: 13px!important;
    }
.input{
    position: absolute;
    transform: translateY(-10px);
    }
</style>