<template>
  <div v-if="admin">
    <mheader ref="header" :admin="admin" :username="user"/>
    <div class="body row">
      <div class="container s12">
        <leadheader
          @allChecked="allChecked"
          :status="status"
          @filter="filter"
          @getAll="this.getAll"
        />
        <ul class="collection">
          <Lead
            v-for="(lead, i) in data"
            :key="lead._id"
            :i="i"
            :lead="lead"
            :data="data"
            :checkedAll="checked"
            class="collection-item row"
            :getAll="getAll"
          />
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import mheader from "../components/header";
import Lead from "../components/Lead.vue";
import leadheader from "../components/lead/leadheader.vue";
export default {
  data() {
    return {
      status: {},
      data: [],
      title: "",
      admin: false,
      username: "",
      isClicked: false,
      editTask: false,
      newTitle: "",
      checked: false,
    };
  },
  async created() {
    await this.testAuth();
    await this.getAll();
  },
  mounted() {},
  name: "Admin",
  components: { Lead, leadheader, mheader },
  props: {},
  computed: {
    amount() {
      return this.data.length;
    },
  },
  watch: {
    checked(newValue) {
      this.checked = newValue;
    },
  },
  methods: {
    async testAuth(){
      const isAuth = await this.$axios.post("user/testAuth");
      if (isAuth.data.auth) {
        console.log(isAuth.data.user.username)
        this.admin = isAuth.data.auth
        this.user = isAuth.data.user.username
      }
      else{
        this.$router.push({ path: `/admin` })
      }
  },
    async getAll() {
      const data = await this.$axios.get("lead/all");
      this.data = data.data;
      this.data.forEach((el) => {
        if (el.status == 0) {
          this.status[el.status] = "новые";
        }
        if (el.status == 1) {
          this.status[el.status] = "в работе";
        }
        if (el.status == 2) {
          this.status[el.status] = "удалённые";
        }
      });
    },
    allChecked() {
      this.checked = !this.checked;
    },
    filter(filteredData) {
      this.data = [];
      this.data = filteredData;
    },
  },
};
</script>
<style scoped>
.body {
  min-height: 100vh;
  padding-top: 70px;
}
.container {
  backdrop-filter: blur(5px);
}
.input_btn {
  margin: 25px;
}
.badge {
  line-height: unset !important;
}
.clear_btn {
  position: absolute;
  right: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.525s ease-in-out;
  transform: translateY(-10px) scale(0.8);
}
.clear_btn i {
  font-size: 16px !important;
}
.clear_btn:hover {
  opacity: 1;
  transform: translateY(-10px) scale(1);
}
</style>