<template>
<div @click="click" class="master_btn">
  {{label}}
</div>
</template>

<script>
export default {
  name: 'btn',
   data() {
            return {
                // ready: false
            }
        },
        mounted(){},
        watch:{},
        props:{
            label: String,
            ready: Boolean
        },
        computed:{},
        methods:{
            click(){
                this.$emit('sendForm')
            }
        }
}
</script>

<style scoped>
.master_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 250px;
    height: 50px;
    border-radius: 20px;
    border: 2px solid var(--m-white);
    /* color: var(--m-white)!important; */
    font-size: 20px;
    font-weight: 500;
    /* line-height: 40px; */
}
.disabled{
    opacity: .6;
    pointer-events: none;
    z-index: -1;
}

</style>