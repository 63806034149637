
<template>

<div class="cont">
<div class="wrp">
    <div class="col_l">
        <p>- Закольные сваи стабилизации с гидроприводом длиной 5 м.</p>
        <p>- Двигатель дизель: TDME 490</p>
        <p>- Способ передвижения по воде: с помощью гребных винтов</p>
        <p>- Ватерлиния при полном снаряжении: 0,4 м.</p>
        <p>- Вес: 2 т.</p>
        <p>- Понтон: цельнометаллический</p>
        <p>- Подключение оборудования быстроразъемным соединением</p>
    </div>

    <div class="col_c">
        <div class="waves_header">
            <!--WAVES-->
                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g class="parallax">
                        <use xlink:href="#gentle-wave" x="48" y="0"></use>
                        <use xlink:href="#gentle-wave" x="48" y="2"></use>
                        <use xlink:href="#gentle-wave" x="48" y="5"></use>
                        <use xlink:href="#gentle-wave" x="48" y="3"></use>
                    </g>
                </svg>
            <!--WAVES END-->
        </div>
            
        <div class="boat"></div>

    </div>

    <div class="col_r">
        <p>- Габариты земснаряда:<br>4,2 | 2,38 | 2 м.</p>
        <p class="active">- Длина стрелы: 4,2 м.</p>
        <p>- Поворот стрелы: 140<sup>0</sup></p>
        <p>- Сменное навесное оборудование: Ковш | Ковш широкий | Виллы | Насос</p>
        <p>- Глубина разработки: 0 - 4 м.</p>
        <p>- Производительность по пульпе: 160м³/час</p>
        <p>- Способ разработки: фрезерование с измельчением</p>
    </div>
</div>
<div class="ill">
    <div class="gear_small"></div>
    <div class="gear_big"></div>
</div>
</div>


</template>

<script>

export default {
        data() {
            return {
                
        }
        },
        name: 'slide3',
        props:{

        },
        mounted(){

        },
        components:{

        },
        computed: {

        },
        watch: {           
        },
        methods: {

        }
    
}
</script>
<style scoped>
    .cont{
        display: inline-flex;
        margin: 0 100px;
    }

    .col_c{
        margin: 0 50px;
        width: 600px;
        /* height: 600px; */
        /* background-image: var(--zemsanaryad);
        background-size: contain;
        background-repeat: no-repeat; */
    }

    .col_l,
    .col_r{
        width: 300px;
        height: 500px;
        padding-top: 150px;
        color: var(--m-white);
        text-align: left;
    }
    .col_l p,
    .col_r p{
        margin-left: 20px;
        width: fit-content;
    }
    .active{
        border-bottom: 2px solid var(--m-yellow);
    }

        /* ILLUSTRATIONS */
    .wrp{
        position: absolute;
        display: inline-flex;
        left: 100px;

        width: 100vw;
        z-index: 5;
    }    

    .ill{
        position: absolute;
        right: -150px;
    }

    .gear_small{
        width: 250px;
        height: 250px;
        background: var(--gear-small);
        background-size: contain;
        position: absolute;
        right: 0;
        animation: rotate 65s cubic-bezier(.55, .5, .45, .5) infinite;

    }
    .gear_big{
        width: 300px;
        height: 300px;
        background: var(--gear-big);
        background-size: contain;
        position: absolute;
        right: 115px;
        transform: translateY(210px);
        animation: rotate_2 65s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    
        /* BOAT ANIMATION */
  .boat{
        z-index: 3;
        position: absolute;
        background-image: var(--zemsanaryad);
        background-size: contain;
        background-repeat: no-repeat;
        width: 600px;
        height: 600px;
    }
    .waves_header {
        display: block;
        opacity: 1;
        z-index: 1;
        padding-top: 115px;
        visibility: visible;
        position: absolute;
        width: 500px;
        margin-left: 20px;
        color: white;
    }
    .waves {
        /* position: relative; */
        width: 100%;
        height: 10vh;
        margin-bottom: -7px;
        /*Fix for safari gap*/
        min-height: 100px;
        max-height: 150px;
    }
    /* Animation */

    .parallax > use {
        transition: all .525s ease-in-out;
        will-change: transform;
        animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
    }

    .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 7s;
        fill: rgba(23,68,92, .7);

        /* fill: rgba(178, 235, 242, .7); */
    }

    .parallax > use:nth-child(2) {
        animation-delay: -3s;
        animation-duration: 10s;
        animation:
            move-forever 10s cubic-bezier(.55, .5, .45, .5) infinite,
            gradient 15s ease-in-out infinite alternate;
        fill: rgba(178, 235, 242, .5);
    }

    .parallax > use:nth-child(3) {
        animation-delay: -4s;
        animation:
            move-forever 13s cubic-bezier(.55, .5, .45, .5) infinite,
            gradient 15s ease-in-out infinite alternate;
        fill: rgba(178, 235, 242, .3);
        
    }

    .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 20s;
        /* fill: rgba(107, 194, 220, 1); */
        fill: rgba(23,68,92, .7)

    }
    @keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
    }
    @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
    }
    @keyframes rotate_2 {
    0% {
        transform: translateY(210px) rotate(0deg);
    }

    100% {
        transform: translateY(210px) rotate(360deg);
    }
    }
</style>