var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"body",class:_vm.clientWidth < 870 && _vm.clientWidth > 670
      ? 'tiny'
      : '' || _vm.clientWidth < 670
      ? 'ultra_tiny'
      : ''},[_c('div',{ref:"preview",staticClass:"preview",class:_vm.clientWidth < 1000 && _vm.clientWidth > 870
        ? 'medium'
        : '' || (_vm.clientWidth < 870 && _vm.clientWidth > 670)
        ? 'tiny'
        : '' || _vm.clientWidth < 670
        ? 'ultra_tiny'
        : ''},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v("Очистка водоемов любой сложности")]),_c('h2',[_vm._v("Дноуглубление, cтроительство, ландшафтные работы")]),_c('btn',{staticClass:"hero_btn",attrs:{"label":'оставить заявку'},on:{"sendForm":function($event){return _vm.$emit('openModal')}}})],1)]),_c('infographic',{attrs:{"scrollY":_vm.scrollY,"clientWidth":_vm.clientWidth}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }