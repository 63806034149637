import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin.vue'
import Project from '../views/NewProject.vue'
import Dashboard from '../views/Dashboard.vue'
import Lead from '../views/Leads.vue'
import Dev from '../views/Dev'


Vue.use(VueRouter)

const routes = [
  {
    path: '/dev',
    name: 'Dev',
    component: Dev
  }, 
   {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  }, {
    path: '/admin',
    name: 'Admin',
    component: Admin
  }, {
    path: '/dashboard/projects',
    name: 'Project',
    component: Project
  }, {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  }, {
    path: '/dashboard/leads',
    name: 'Leads',
    component: Lead
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router