<template>
  <div data-scroll-class class="body">
  <h2>{{title}}</h2>
  <div class="cont">
      <callback :modal="modal" class="form"/>
      <p class="subtitle">После оформления заявки в течении нескольких минут с Вами<br>
        свяжется наш Специалист для уточнение всех деталей </p>
      <p class="info"><sup>*</sup>Нажимая на кнопку отправить, Вы соглашаетесь с нашей политикой конфиденциальности</p>  
  </div>
  </div>
</template>

<script>
import callback from '../Form'
export default {
        data() {
            return {
            }
        },
        name: 'seccallback',
        props:{
            title: String,
            modal: Boolean
        },
        components:{
            callback
        },
        computed: {

        },
        watch: {
            
        },
        methods: {

        }
    
}
</script>
<style scoped>
.body{
    width: 100%;
    background-image: url('../../assets/img/bg/callback_bg.png');
    /* background-image: var(--callback); */
    background-color: var(--m-blue);
    background-size: cover;
    /* height: 900px; */
}
h2{
    margin: 0;
    padding: 50px 0;
    color: var(--m-white);
    font-size: 35px;
    font-weight: 900;
    text-transform: uppercase;

}
.cont{
    margin: 0 100px;
}
.form{
    width: 500px;
    margin: 0 auto;
}
.subtitle{
    margin: 0 auto;
    padding: 50px 0;
    opacity: .7;
    font-weight: 500;
    font-size: 18px;
    color: var(--m-white);
    }
    .info{
    margin: 0 auto;
    padding-bottom: 50px;
    opacity: .5;
    font-weight: 500;
    font-size: 14px;
    color: var(--m-white);
    }
 
</style>