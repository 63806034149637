
<template>
  <section
    class="body"
    :class="
      clientWidth < 1200 && clientWidth > 1100
        ? 'medium'
        : '' || (clientWidth < 1100 && clientWidth > 900)
        ? 'small'
        : '' || (clientWidth < 900 && clientWidth > 720)
        ? 'tiny'
        : '' || (clientWidth < 720 && clientWidth >670) 
        ? 'ultra_tiny'
        : '' || clientWidth < 670
        ? 'mobile'
        : ''
    "
  >
  <a name="about"></a>
    <h2>{{ title }}</h2>
    <div class="cont">
      <div class="col_l">
        <h3>Компания «Акваберег»</h3>
        <p>
          Выполняет все виды гидротехнических и ландшафтных работ на водоемах, в
          том числе очистку от мусора, ила и растений.<br /><br />
          Мы работаем на земснаряде «Боцман РА». Это плавающая малогабаритная
          техника с высокой производительностью: 160<sup>м3</sup> по пульпе.
          Если в водоеме есть рыба, техника ей не навредит.<br /><br />
          Земснаряд передвигается по воде, поэтому не наносит ущерба ландшафту.
          По своей функциональности техника может заменить экскаватор, драглайн,
          кран или погрузчик.
        </p>
        <h4>Максимальная глубина работ - 4 м.</h4>
      </div>
      <div class="col_r">
        <div class="preview_img"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: "secabout",
  props: {
    title: String,
    clientWidth: Number,
  },
  components: {},
  computed: {},
  watch: {},
  methods: {},
};
</script>
<style scoped>
h2 {
  margin: 0;
  padding: 50px 0;
  color: var(--m-blue);
  font-size: 35px;
  font-weight: 900;
  text-transform: uppercase;
}
h3 {
  text-align: left;
  font-weight: 900;
  font-size: 25px;
  color: var(--m-blue);
}
h4 {
  text-align: left;
  font-weight: 900;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--m-blue);
}
p {
  text-align: left;
  color: var(--m-blue);
  font-size: 17px;
  font-weight: 500;
  padding-right: 25px;
}
.cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 100px;
  /* margin: 0 100px; */
  padding-bottom: 50px;
}
.col_l,
.col_r {
  backdrop-filter: blur(2px);
}
.col_l {
  max-width: 500px;
}
.col_r {
  margin: 0 auto;
  /* display: flex;
        flex-wrap: wrap; */
}
.preview_img {
  width: 480px;
  height: 450px;
  border-radius: 100px 20px 100px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 4px solid whitesmoke;
  box-shadow: inset var(--white), var(--black);
  background-image: url("../../assets/img/bw_about_img_1.jpg");
}
.medium .cont,
.small .cont,
.tiny .cont ,
.ultra_tiny .cont{
  padding: 0 50px;
}
.mobile .col_l{
    max-width: 360px;
}
.mobile .cont {
  padding: 0 25px;
}
.small .cont p,
.tiny .cont p {
  font-size: 15px;
  max-width: 375px;
}
.mobile .cont p {
  font-size: 15px;
  }
.small .cont h4 ,
.tiny .cont h4 {
  font-size: 16px;
}
.small .cont .col_r .preview_img {
  width: 350px;
  height: 400px;
}
.ultra_tiny .cont,
.tiny .cont,
.mobile .cont{
    flex-direction: column-reverse;
}
.tiny .col_l,
.tiny .col_r .preview_img{
    max-width: 800px;
    width: 650px;
}
.mobile .col_r .preview_img{
    width: 300px;
    height: 300px;
    border: 2px solid whitesmoke;
}
.tiny .cont p {
    max-width: 800px;
}
.mobile .cont p {
    font-size: 12px;
    line-height: 14px;

}
.mobile h2{
    font-size: 25px;
}
.mobile h3{
    font-size: 20px;
}
.mobile h4{
    font-size: 15px;
}
</style>