`<template>
  <div>
    <!-- CLIENT HEADER  -->
    <div id="header_block" v-if="!admin" :class="clientWidth < 670 ? 'mobile' : '' ">
      <div class="logo"></div>
      <ul
        v-if="clientWidth > 970"
        class="links"
        :class="clientWidth < 1080 ? 'small' : ''"
        ref="header_links_wrp"
      >
        <li :class="nav.hero ? 'active' : ''" @click="click('hero')">
          главная
        </li>
        <li :class="nav.about ? 'active' : ''" @click="click('about')">
          о нас
        </li>
        <li :class="nav.services ? 'active' : ''" @click="click('services')">
          услуги
        </li>
        <li :class="nav.prices ? 'active' : ''" @click="click('prices')">
          стоимость
        </li>
        <li :class="nav.tech ? 'active' : ''" @click="click('tech')">
          техника
        </li>
        <li :class="nav.zones ? 'active' : ''" @click="click('zones')">
          география работ
        </li>
        <li
          :class="nav.contacts ? 'active' : ''"
          @click="click('contacts')"
          ref="last_link"
          class="last_link"
        >
          контакты
        </li>
      </ul>
      <div v-else class="white_space"></div>
      <div class="phone">
        <i v-if="clientWidth > 970" class="material-icons">phone</i>
        <i v-else class="material-icons">menu</i>
        <a v-if="clientWidth > 1220" href="tel:+7(985)930-15-91"
          >+7(985) 930-15-91</a
        >
      </div>
    </div>

    <!-- ADMIN HEADER -->
    <div id="header_block" v-else>
      <div class="logo"></div>
      <ul class="links">
        <li @click="$router.push({ path: `/` })">на сайт</li>
        <li @click="$router.push({ path: `/dashboard/leads` })">заявки</li>
        <li @click="$router.push({ path: `/dashboard/visits` })">посетители</li>
        <li @click="$router.push({ path: `/dashboard/users` })">
          пользователи
        </li>
        <li @click="$router.push({ path: `/dashboard/projects` })">проекты</li>

        <li
          class="user"
          @mouseover="dropdown($refs.trigger, $refs.dropdown, (test = true))"
          @mouseleave="dropdown($refs.trigger, $refs.dropdown, (test = false))"
          @click="dropdown($refs.trigger, $refs.dropdown, (test = !test))"
          ref="trigger"
        >
          <i class="material-icons">person</i>
          {{ username }}
          <i :class="test ? 'is_active' : 'is_hidden'" class="material-icons"
            >arrow_drop_down</i
          >
        </li>
      </ul>
    </div>
    <span
      @mouseover="dropdown($refs.trigger, $refs.dropdown, (test = true))"
      @mouseleave="dropdown($refs.trigger, $refs.dropdown, (test = false))"
      class="dropdown"
      :class="test ? 'is_active' : 'is_hidden'"
      ref="dropdown"
    >
      <ul>
        <li @click="logOut">выйти <i class="material-icons">exit_to_app</i></li>
      </ul>
    </span>
  </div>
</template>

<script>
export default {
  name: "header_block",
  data() {
    return {
      test: false,
      isHover: false,
    };
  },
  mounted() {},
  created() {

  },
  components: {},
  watch: {

  },
  props: {
    nav: Object,
    admin: Boolean,
    username: String,
    clientWidth: Number
  },
  computed: {},
  methods: {
    click(nav) {
      this.$emit("nav", nav);
    },
    dropdown(master, slave) {
      const a = master.getBoundingClientRect();
      const b = slave;
      const height = a.height;
      const width = a.width;
      const left = a.left + pageXOffset;
      b.style.top = height + "px";
      b.style.width = width + "px";
      b.style.left = left + "px";
    },
    async logOut() {
      await this.$axios.get("/user/log_out");
      window.location.reload();
    },

  },
};
</script>
<style scoped>
#nav {
  height: 50px;
  width: 100vw;
}
#header_block {
  top: 0;
  padding-left: 100px;
  z-index: 999;
  display: flex;
  color: var(--m-white);
  height: 50px;
  width: 100vw;
  background: transparent;
  position: fixed;
  background: var(--m-blue);
}

#nav a {
  font-weight: bold;
  line-height: 50px;
  color: #2c3e50;
  /* color: rgb(241,231,217) */
}
.links {
  margin: 0 auto;
  display: flex;
  font-size: 16px;
  width: 750px;
}
.small {
  font-size: 14px !important;
  width: 650px;
}
.logo {
  margin-top: 5px;
  width: 200px;
  height: 45px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/svg/logo.svg");
}
#header_block li {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;
  opacity: 0.7;
  margin-bottom: 5px;
  font-weight: 700;
  line-height: 50px;
  border-bottom: 2px solid transparent;
}
#header_block li.active {
  border-bottom: 2px solid var(--m-yellow);
  opacity: 1;
}
.user {
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.is_hidden {
  opacity: 0;
  transform: translateY(-20px);
  visibility: hidden;
  backdrop-filter: blur(0px);
}
.is_active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  backdrop-filter: blur(5px);
}
.dropdown {
  position: fixed;
  transition: all 0.525s ease-in;
  overflow: hidden;
  border-radius: 0 0 15px 15px;
  color: var(--m-white);
  z-index: 88;
  background: var(--m-blue);
  box-shadow: var(--black);
  padding-bottom: 15px;
}
.material-icons {
  font-size: 20px !important;
  padding-right: 5px;
}

.dropdown li {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--m-white);
  transition: all 0.525s ease-in-out;
  transform: scale(1);
  height: 35px;
}
.dropdown li .material-icons {
  font-size: 15px !important;
  padding-left: 5px;
}
.dropdown li:hover {
  transform: scale(1.05);
}
.phone {
  font-size: 15px !important;
  padding: 0 25px;
  margin: 0 auto;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 50px;
}
.phone i {
  font-size: 18px !important;
}

.white_space {
  width: 100%;
  margin: 0 auto;
}
#header_block.mobile{
  padding-left: 25px;
  align-items: center;
}
#header_block.mobile .white_space{
  display: none;
  visibility: hidden;
}
#header_block.mobile .logo{
  margin-top: 0!important;
  width: 100px;
}
#header_block.mobile .phone{
  position: absolute;
  right: 25px;
}

</style>