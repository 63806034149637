
<template>
  <div v-if="admin">
    <mheader @nav="scrollTo" ref="header" :nav="nav" :admin="admin" :username="user"/>
    <h2>this is dashboard</h2>
  </div>
</template>

<script>
import mheader from "../components/header";

export default {
  data() {
    return {
      admin: false,
      user: ""
    };
  },
  name: "Dashboard",
  props: {},
  async created() {
    await this.testAuth();
  },
  components: {mheader},
  computed: {},
  watch: {},
  methods: {
 async testAuth(){
      const isAuth = await this.$axios.post("user/testAuth");
      if (isAuth.data.auth) {
        console.log(isAuth.data.user.username)
        this.admin = isAuth.data.auth
        this.user = isAuth.data.user.username
      }
      else{
        this.$router.push({ path: `/admin` })
      }
  },
  },
};
</script>